<template>
<div class="base-form email-form">
	<h4 class="d-sm-none"><strong>Enter your email</strong> to&nbsp;register or sign&nbsp;in:</h4>
	<div class="form-field">
		<label for="registration-form-email" class="d-none d-sm-block">Email <span class="comment">used as login</span></label>
		<input type="email" name="email" ref="email" :class="[ 'email-field', { error: error } ]" id="registration-form-email" placeholder="user@gmail.com" autocomplete="email" required v-model="email" @input="input" @keydown="keydown" @focus="$emit('focus', 'email')" />
		
		<span v-if="error" class="error-text">{{ error }}</span>
		<button @click="submit" :class="[ 'submit', 'd-none', 'd-sm-block', { 'd-block': show_button_on_mobile } ]" :disabled="!validate() || submitting">
			{{ submitting ? 'Submitting...' : 'Continue' }}
			<font-awesome-icon :icon="['far', 'chevron-right']" class="icon" v-show="!submitting" />
		</button>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputToLocalStorage from '~/mixins/InputToLocalStorage'

export default
{
	mixins: [ InputToLocalStorage ],
	
	data: () => ({
		email: '',
		error: null,
		show_button_on_mobile: true,
		submitting: false,
	}),
	
	computed: {
		...mapState({
			customer: state => state.customer,
		}),
	},
	
	methods: {
		...mapActions([
			'updateCustomerData',
		]),
		
		input(event)
		{
			const email = event.target.value.trim()
			
			this.saveToLocalStorage('email', email)
			
			if (email)
				this.show_button_on_mobile = true
			else
				this.show_button_on_mobile = false
			
			if (this.validate())
			{
				this.error = null
				this.updateCustomerData({ email: email })
			}
		},
		
		keydown(event)
		{
			if (!event)
				return

			switch (event.keyCode)
			{
				case 13:
					this.submit()
				return
			}
		},
		
		focus()
		{
			if (this.$refs.email)
				this.$refs.email.focus()
		},
		
		validate()
		{
			const re = /\S+@\S+\.\S+/
			return re.test(this.email)
		},
		
		async submit()
		{
			if (!this.validate())
			{
				this.error = null
				setTimeout(() => {
					this.error = 'Please enter a correct email address'
				}, 1)
				
				return
			}
			
			this.updateCustomerData({ email: this.email.trim().toLowerCase() })
			
			this.error = null
			this.submitting = true
			
			// TODO: add support for captcha mode
			const result = await this.$api.checkIfCustomerIsRegistered({ email: this.email.trim().toLowerCase() })
			
			this.submitting = false
			if (!result || result.status != 'ok')
			{
				this.error = 'Error confirming email. Please try again.'
				return
			}
			
			if (result.mode)
			{
				this.$emit('submit', {
					mode: result.mode,
					// TODO: add info about available ways to login for this customer
				})
				
				this.$track.event({
					gtm: {
						category: 'Account',
						action: 'Email submitted',
						label: 'Next mode: ' + result.mode,
					},
					fb: {
						event: 'AccountEmailSubmitted',
					}
				})
			}
		},
	},
	
	mounted()
	{
		if (!this.email && this.customer.email)
			this.email = this.customer.email
		
		if (!this.email)
		{
			const e = this.getFromLocalStorage('email')
			if (e)
				this.email = e
		}
		
		if (!this.email)
			this.show_button_on_mobile = false
		
		this.$nextTick(function () {
			this.focus()
		})
	},
}
</script>
<style lang="scss" scoped>
.email-form {
	margin: 0 auto;
	padding: 40px 0;
	max-width: 314px;
	
	@include media-breakpoint-down(xs) {
		max-width: none;
		padding: 30px 20px 20px 20px;
	}
}

.email-field {
	position: relative;
	margin-bottom: 0;
	
	background: #fff url(/images/lock.svg) no-repeat 97% 50%;
	background-position: calc(100% - 10px) 50%;
}
</style>