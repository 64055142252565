const TrackOrder = {
	methods:
	{
		trackOrder(order)
		{
			this.$track.event({
				gtm: {
					category: 'Checkout',
					action: 'Order submitted',
					label: order.number,
				},
				ecom: {
					'purchase': {
						actionField: {
							id: order.number,
							affiliation: 'homekilo.com',
							revenue: order.total, // incl. tax and shipping
							tax: order.tax,
							shipping: order.shipping_total,
						},
						products: order.items.map(item => ({
							id: item.sku,
							name: item.title,
							variant: item.params ? item.params : '',
							quantity: item.quantity,
							price: item.list_price,
							brand: item.brand.name,
						})),
					}
				},
				fb: {
					event: 'Purchase',
					content_ids: order.items.map(item => item.sku),
					content_type: 'product',
					currency: 'USD',
					value: order.total,
				},
			})
		},
	}
}

export default TrackOrder