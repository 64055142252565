<template>
<Modal compact class="no-spacing registration-form-modal" @close="close">

<div class="d-none d-sm-block header">
	<!-- <span class="close-modal" @click="close">&times;</span> -->
	
	<div class="row no-gutters">
		<div class="col">
			<h2 v-if="mode == 'email'">Register or Sign&nbsp;In</h2>
			<h2 v-if="mode == 'register'">Create an&nbsp;account</h2>
			<h2 v-if="mode == 'login'">Sign&nbsp;In</h2>
			
			<p>
				<template v-if="mode == 'email'">
					Enter your email below to sign in or create an account at homekilo.
				</template>
				
				<template v-if="mode == 'register'">
					Enter your name and&nbsp;create a&nbsp;password to&nbsp;get 10%&nbsp;off.
				</template>
				
				<template v-if="mode == 'login'">
					Enter your password to&nbsp;sign in.
				</template>
				
				<br />We do not sell customer data or send spam. <strong>You are in control.</strong>
			</p>
			
			<p><nuxt-link to="/privacy-terms/" target="_blank">Privacy &amp; terms</nuxt-link></p>
		</div>
		
		<div class="col-2 header-icon">
			<font-awesome-icon :icon="['far', 'lock-alt']" class="icon" v-if="mode == 'email' || mode == 'login'" />
			<font-awesome-icon :icon="['fas', 'badge-percent']" class="icon highlight" v-if="mode == 'register'" />
		</div>
	</div>
</div>

<div class="content modes">

	<Email v-if="mode == 'email'" @submit="submit" :class="animation" />
	
	<Register v-if="mode == 'register'" @submit="submit" :class="animation" />
	
	<Login v-if="mode == 'login'" @submit="submit" :class="animation" />
	
	
	<!-- <ExternalAuthServices v-if="mode == 'email' || mode == 'login'" :class="animation" /> -->
</div>
</Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Modal from '~/components/common/Modal'
import Email from '~/components/signup/Email'
import Register from '~/components/signup/Register'
import Login from '~/components/signup/Login'
// import ExternalAuthServices from '~/components/signup/ExternalAuthServices'

export default
{
	components: {
		Modal,
		Email,
		Register,
		Login,
		// ExternalAuthServices,
	},
	
	data: () => ({
		mode: 'email',
		animation: '',
	}),
	
	computed: {
		...mapState({
			customer: state => state.customer,
		}),
	},

	methods: {
		...mapActions([
			'hideRegistrationForm',
		]),
		
		close()
		{
			this.hideRegistrationForm()
			
			this.$track.event({
				gtm: {
					category: 'Account',
					action: 'Registration form closed',
				},
				fb: {
					event: 'AccountRegistrationFormClosed',
				}
			})
		},
		
		submit({ mode })
		{
			switch (mode)
			{
				case 'email':
					this.animation = 'slide-right'
				return this.mode = 'email'
				
				case 'register':
					this.animation = 'slide-left'
				return this.mode = 'register'
				
				case 'login':
					this.animation = 'slide-left'
				return this.mode = 'login'
				
				default:
					this.animation = ''
			}
		},
	},
	
	mounted()
	{
		this.$track.event({
			gtm: {
				category: 'Account',
				action: 'Registration form opened',
			},
			fb: {
				event: 'AccountRegistrationFormOpened',
			}
		})
	},
}
</script>

<style lang="scss">
.registration-form-modal {
	
	h4 {
		font-size: 16px;
		font-weight: normal;
		text-align: center;
		margin: 0 0 20px 0;
	}
	
	.form-field {
		input {
			&.error {
				border: solid 1px #d00;
				animation: shake 0.3s linear;
			}
		}
		
		.comment {
			font-size: 13px;
			font-weight: 100;
			margin-left: 3px;
		}
	}
	
	.error-text {
		color: #b00;
		display: block;
		margin: 5px 0;
		font-size: 13px;
	}

	.submit {
		@include main-button;

		display: block;
		width: 100%;
		margin: 20px 0 0 0;
		padding-left: 40px;
		
		font-size: 16px;
		font-weight: 500;
		padding-top: 13px;
		padding-bottom: 14px;
		line-height: 1;
		white-space: nowrap;

		.icon {
			font-size: 12px;
			margin-left: 5px;

			animation: horizontal 3s ease infinite;
		}
		
		&:disabled {
			.icon {
				animation: none;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.header {
	padding: 25px 25px 5px 25px;
	font-size: 14px;
	
	h2 {
		margin: 0;
		font-size: 22px;
	}
	
	p {
		margin: 10px 0;
	}
	
	a {
		text-decoration: none;
	}
	
	.header-icon {
		text-align: center;
		font-size: 60px;
		
		.highlight {
			color: #ff6c00;
		}
	}
}

.content {
	margin: 0;
	background: #eaeaea;
	border-top: solid 1px $border-color-2;
	
	@include media-breakpoint-down(xs) {
		background: #f4f4f4;
	}
}

.slide-left {
	animation: slide-left 0.2s ease-out;
}

.slide-right {
	animation: slide-right 0.2s ease-out;
}
</style>