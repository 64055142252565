<template>
<Modal with-header compact @close="close">

<div class="row header">
	<div class="col-10 left">
		<div class="content">
			<h2>Get notified</h2>
		</div>
	</div>
	<div class="col-2 right">
		<span class="close-modal" @click="close">&times;</span>
	</div>
</div>

<div class="content">

	<p>Enter your email below to&nbsp;get notified when <strong>{{ product.title }}</strong> is back in&nbsp;stock. <nuxt-link to="/privacy-and-terms/" target="_blank">Privacy&nbsp;&amp;&nbsp;terms</nuxt-link></p>
	
	<div class="form">
		<input type="text" name="name" placeholder="Full name" />
		<input type="email" name="email" placeholder="user@gmail.com" />
		<button>Notify me</button>
	</div>

</div>
</Modal>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '~/components/common/Modal'

export default {
	components: {
		Modal,
	},

	props: [
		'product'
	],

	methods: {
		...mapActions([
			'hideNotifyMe'
		]),

		close()
		{
			this.hideNotifyMe()
		},
	},
}
</script>

<style lang="scss" scoped>
.close-modal {
	@include media-breakpoint-down(xs) {
		display: inline-block;
		margin-top: 10px;
		margin-right: 10px;
	}
}
</style>