import { mapState, mapActions } from 'vuex'

import { loadStripe } from '@stripe/stripe-js'

const Stripe = {
	data: () => ({
		stripe: null,
	}),
	
	computed: {
		
	},

	methods: {
		async initStripe()
		{
			if (!this.stripe)
			{
				const STRIPE_PUBLIC_KEY_TEST = 'pk_test_U2XxYcm9oRmiPjXYfG70f12O00xyblE3ix'
				const STRIPE_PUBLIC_KEY_LIVE = 'pk_live_PoWw30NZI83VgdXoQgxGlN4Y00DNTg8PQ1'
				this.stripe = await loadStripe(process.env.NODE_ENV == 'production' ? STRIPE_PUBLIC_KEY_LIVE : STRIPE_PUBLIC_KEY_TEST)
			}
			
			return this.stripe
		},
	},
}

export default Stripe