<template>
<div :class="[ 'cart-product', { 'out-of-stock': !product.available } ]">
		<div class="row">
			<div class="col-4 col-lg-1 align-self-center image">
				<nuxt-link :to="product.full_url" v-if="product.images && product.images.length" @click.native="hideCart() && hideSavedForLater()"><img :src="'/images/products/' + product.sku + '/' + product.images[0] + '/360.jpg'" :key="product.images[0]" :alt="product.title" /></nuxt-link>
			</div>
			<div class="col-8 col-lg align-self-center title">
				<nuxt-link :to="product.full_url" @click.native="hideCart() && hideSavedForLater()" v-html="product.title"></nuxt-link>
			</div>
			<div class="col-4 offset-4 offset-lg-0 col-lg-2 align-self-center quantity" v-if="product.available">
				<span v-if="item.quantity > 1">
					<Currency :price="item.cart_price" currency="USD" />
					<span class="times">&times;</span>
				</span>
				<span class="quantity-selector">
					<span @click.stop="toggleQuantitySelectorList">Qty:&nbsp;&nbsp;<strong>{{ item.quantity }}&nbsp;<font-awesome-icon :icon="['fas', 'caret-down']" /></strong></span>
					
					<span class="list" v-show="quantitySelectorListVisible">
						<span v-for="i in Math.min(product.available, 10)" :key="i" @click.stop="setQuantity(i)" :class="{ 'active': i == item.quantity }">{{ i }}</span>
					</span>
				</span>
			</div>
			<div class="col-4 col-lg-2 align-self-center price-block" v-if="product.available">
				<span v-if="product.original_price && product.original_price > product.cart_price">
					<Currency :price="item.original_price * item.quantity" currency="USD" class="original-price" />
				</span>
				<Currency :price="item.cart_price * item.quantity" currency="USD" class="price" />
			</div>
		</div>
		<div class="controls">
			<div class="row">
				<div class="d-none d-lg-block col-lg-1 image-spacer">
				</div>
				<div class="col-4 col-lg stock-info">
					<span class="in-stock" v-if="product.available > 5">In stock</span>
					<span class="low-inventory" v-if="product.available && product.available <= 5">Only {{ product.available }} left, order soon</span>
					<span class="out-of-stock" v-if="!product.available">Out of stock</span>
					<!-- TODO: --><!-- <span class="delivery-estimate">Order within 12 hrs 14 mins to get it by Friday, December 13</span> -->
				</div>
				<div class="col-8 col-lg actions">
					<span class="save" @click="saveForLater()">Save for later</span>
					<span class="delete" @click="deleteFromCart()">Delete</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Currency from '~/components/common/Currency'

export default {
	components: {
		Currency,
	},

	props: [
		'item',
		'product'
	],

	data: function()
	{
		return {
			quantitySelectorListVisible: false
		}
	},

	methods: {
		...mapActions([
			'setCustomerCart',
			'setCartProducts',
			'setCustomerLists',
			'setListsProducts',
			'hideCart',
			'hideSavedForLater',
		]),

		toggleQuantitySelectorList()
		{
			this.quantitySelectorListVisible = !this.quantitySelectorListVisible
			if (this.quantitySelectorListVisible)
			{
				this.$nextTick(() => {
					window.addEventListener('click', this.hideQuantitySelectorList)
				})
			}
			else
				window.removeEventListener('click', this.hideQuantitySelectorList)
		},

		hideQuantitySelectorList()
		{
			this.quantitySelectorListVisible = false
			window.removeEventListener('click', this.hideQuantitySelectorList)
		},

		async setQuantity(quantity)
		{
			if (quantity == this.item.quantity)
				return this.hideQuantitySelectorList()
			
			const r = await this.$api.setCartProductQuantity({ ...this.item, quantity: quantity })
			if (r && r.status == 'ok')
			{
				this.setCustomerCart(r.cart)
				// this.setCartProducts(r.cart_products)
			}

			this.hideQuantitySelectorList()
		},
		
		async saveForLater()
		{
			const r = await this.$api.saveForLater(this.item)
			if (r && r.status == 'ok')
			{
				this.setCustomerCart(r.cart)
				// this.setCartProducts(r.cart_products)
				
				if (r.lists && r.lists.length)
				{
					this.setCustomerLists(r.lists)
					this.setListsProducts(r.lists_products)
				}
			}
		},

		async deleteFromCart()
		{
			if (this.product.available && !confirm('Are you sure you want to delete ' + this.product.title + ' from your shopping cart?'))
				return
			
			const r = await this.$api.deleteProductFromCart(this.item)
			if (r && r.status == 'ok')
			{
				this.setCustomerCart(r.cart)
				// this.setCartProducts(r.cart_products)
				
				this.$track.event({
					gtm: {
						category: 'Product',
						action: 'Remove from cart',
						label: this.product.sku,
					},
					ecom: {
						currencyCode: 'USD',
						remove: {
							products: [{
								name: this.product.title,
								id: this.product.sku,
								price: this.item.cart_price,
								brand: this.product.brand.name,
								variant: '',
								quantity: this.item.quantity,
							}],
						},
					},
					fb: {
						event: 'RemoveFromCart',
						content_name: this.product.title,
						content_ids: [this.product.sku],
					},
				})
			}
		},
	},
	
	mounted()
	{
		if (!this.item.cart_price && this.item.list_price)
			this.item.cart_price = this.item.list_price
	},
}
</script>

<style lang="scss" scoped>
.cart-product {
	margin-bottom: 20px;
	border: solid 1px #cfcfcf;

	&.out-of-stock {
		background: #fee;

		.controls {
			background: #edd;
		}
	}

	a {
		color: #000;
		text-decoration: none;

		&:hover {
			color: $hover-color;
		}
	}

	.image-spacer,
	.image {
		padding-left: 35px;
		min-width: 140px;
		
		@include media-breakpoint-down(md) {
			// padding-left: 25px;
			min-width: 0;
		}
	}

	.image {
		padding-top: 20px;
		min-height: 90px;
		// background: #ff0;
		
		@include media-breakpoint-down(md) {
			padding-top: 20px;
			min-height: 0;
		}

		img {
			// align-self: center;
			width: 100px;
			position: absolute;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
			
			@include media-breakpoint-down(md) {
				position: static;
				width: 100%;
				box-shadow: none;
			}
		}
	}
	
	.title {
		@include media-breakpoint-down(md) {
			padding-top: 20px;
			padding-right: 35px;
			padding-left: 5px;
		}
	}

	.quantity {
		text-align: right;
		font-size: 14px;
		white-space: nowrap;
		
		@include media-breakpoint-down(md) {
			text-align: left;
			// padding-left: 35px;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 5px;
		}

		.times {
			margin: 0 0.6rem;
			
			@include media-breakpoint-down(md) {
				margin: 0 0.3rem;
			}
		}

		.quantity-selector {
			cursor: pointer;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			line-height: 14px;
			border: none;
			position: relative;

			&:hover {
				color: $hover-color;
			}

			.list {
				z-index: 100;
				position: absolute;
				top: 20px;
				right: 0;
				display: block;
				color: #000;
				border: solid 1px #cfcfcf;
				background: #fafafa;
				box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.13);

				span {
					display: block;
					padding: 4px 11px;

					&:hover {
						color: #fff;
						background: $hover-color;
					}

					&.active {
						color: #fff;
						background: lighten($hover-color, 10%);
					}
				}
			}
		}
	}

	.price-block {
		text-align: right;
		font-weight: 900;
		padding-right: 35px;
		
		@include media-breakpoint-down(md) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	
	.original-price {
		font-weight: 300;
		// margin-right: 5px;
		@include strikeout;
	}
	
	.price {
		margin-left: 5px;
	}

	.controls {
		border-top: solid 1px #cfcfcf;
		background: #f7f7f7;
		// min-height: 45px;
		padding-top: 13px;
		padding-bottom: 13px;

		.stock-info {
			font-size: 12px;
			
			@include media-breakpoint-down(md) {
				padding-left: 35px;
			}

			span {
				white-space: nowrap;
				margin-right: 0.5rem;

				&.in-stock {
					color: #1c7b00;
				}

				&.low-inventory {
					color: #d60;
				}

				&.out-of-stock {
					color: #800;
				}

				&.delivery-estimate {
					font-style: italic;
				}
			}
		}

		.actions {
			padding-right: 35px;
			font-size: 13px;
			text-align: right;
			white-space: nowrap;

			.save {
				cursor: pointer;
				margin-right: 1.5rem;

				&:hover {
					color: $hover-color;
				}
			}

			.delete {
				cursor: pointer;
				color: #730000;

				&:hover {
					color: #ff0000;
				}
			}
		}
	}
}
</style>