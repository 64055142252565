<template>
<div>
	<h2>Your saved products</h2>
	
	<div class="row">
		<div class="col-12 col-sm-6 col-md-4 saved-item" v-for="item in saved" :key="item.sku">
			<div class="row no-gutters">
				<span class="col-3 image">
					<nuxt-link :to="lists_products[item.sku].full_url" @click.native="hideCart() && hideSavedForLater()">
						<img :src="'/images/products/' + lists_products[item.sku].sku + '/' + lists_products[item.sku].images[0] + '/360.jpg'" :key="lists_products[item.sku].images[0]" :alt="lists_products[item.sku].title" />
					</nuxt-link>
				</span>
				<span class="col">
					<nuxt-link :to="lists_products[item.sku].full_url" @click.native="hideCart() && hideSavedForLater()">
						{{ lists_products[item.sku].title }}
					</nuxt-link>
					
					<div class="price-block">
						<Currency :price="lists_products[item.sku].discounted_price" currency="USD" class="price" />
						<span class="add-to-cart-button" v-if="lists_products[item.sku].available" @click="moveToCart(item.sku)">Add to cart</span>
						<span class="out-of-stock-message" v-else>Out of stock</span>
					</div>
				</span>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Currency from '~/components/common/Currency'

export default
{
	components: {
		Currency,
	},
	
	computed: {
		...mapState({
			saved: state => state.customer.lists[0].items,
			lists_products: state => state.lists_products,
			show_cart: state => state.show_cart,
			show_saved_for_later: state => state.show_saved_for_later,
		}),
	},

	methods: {
		...mapActions([
			'showCart',
			'hideCart',
			'hideSavedForLater',
			'setCustomerCart',
			'setCartProducts',
			'setCustomerLists',
			'setListsProducts',
		]),
		
		async moveToCart(sku)
		{
			const r = await this.$api.moveToCart({ sku: sku })
			if (r && r.status == 'ok')
			{
				this.setCustomerCart(r.cart)
				// this.setCartProducts(r.cart_products)
				
				this.setCustomerLists(r.lists)
				this.setListsProducts(r.lists_products)
				
				if (!this.show_cart)
					this.showCart()
				
				if (this.show_saved_for_later)
					this.hideSavedForLater()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
h2 {
	margin: 0;
}

.saved-item {
	margin-top: 30px;
	margin-bottom: 10px;
	line-height: 1.2;
	
	a {
		color: #000;
		text-decoration: none;
		
		&:hover {
			color: $hover-color;
		}
	}
	
	.image {
		max-width: 100px;
		margin-right: 20px;
		
		img {
			width: 100%;
		}
	}
	
	.price-block {
		margin: 15px 0;
		
		.price {
			font-weight: 900;
		}
		
		.add-to-cart-button {
			cursor: pointer;
			font-size: 13px;
			margin-left: 7px;
			padding: 3px 8px;
			border: solid 1px $border-color-2;
			border-radius: 12px;
			white-space: nowrap;
			
			&:hover {
				color: $hover-color;
				border-color: $hover-color;
			}
		}
		
		.out-of-stock-message {
			margin-left: 7px;
			font-size: 14px;
			color: #800;
		}
	}
}
</style>