<template>
<div class="search-bar-container">
	<div class="search-bar">
		<div class="search-icon" @click="focus"><font-awesome-icon :icon="['far', 'search']" /></div>
		<input type="text" ref="search_input" :value="query" @keypress.enter="search" @input="input" placeholder="Search products, colors, sizes, materials" aria-label="Search products, colors, sizes, materials" />
		<client-only><div class="search-help" @click="focus">Press
			<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g transform="translate(1.000000, 0.000000)">
						<g>
							<path d="M4.674,12.504 C4.738,12.504 4.803,12.486 4.869,12.45 C4.935,12.414 4.986,12.358 5.022,12.282 L5.022,12.282 L9.522,3.33 L9.288,3.33 C9.132,3.33 9.02,3.402 8.952,3.546 L8.952,3.546 L4.446,12.504 L4.674,12.504 Z" fill="#8E8E8E" fill-rule="nonzero"></path>
							<rect stroke="#979797" stroke-width="0.5" x="0" y="1" width="14" height="14" rx="2"></rect>
						</g>
					</g>
				</g>
			</svg>
			to&nbsp;focus</div></client-only>
	</div>
	<div class="top-searches">Top searches: <nuxt-link to="/search/dresser/">Dresser</nuxt-link>, <nuxt-link to="/search/mirror/">Mirror</nuxt-link>, <nuxt-link to="/search/headboard/">Headboard</nuxt-link>, <nuxt-link to="/search/accent%20chair/">Accent chair</nuxt-link>, <nuxt-link to="/search/cabinet/">Cabinet</nuxt-link>, <nuxt-link to="/search/bookcase/">Bookcase</nuxt-link></div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default
{
	computed: {
		...mapState({
			query: state => state.search.query,
			results: state => state.search.results,
		}),
	},
	
	methods: {
		...mapActions([
			'setSearchQuery',
		]),
		
		focus: function()
		{
			this.$refs.search_input.focus()
			this.$refs.search_input.select()
		},

		keyFocus: function(event)
		{
			// Press [/] key to focus (191 for keyup/keydown, 47 for keypress)
			if (event.keyCode !== 191)
				return
			
			event = event || window.event
			var target = event.target || event.srcElement
			if ( !/INPUT|TEXTAREA|SELECT|BUTTON/i.test(target.nodeName) )
			{
				this.focus()
				event.preventDefault()
			}
		},
		
		input(event)
		{
			// TODO: autocomplete
		},
		
		search()
		{
			// this.setSearchQuery(this.$refs.search_input.value)
			this.$router.push({
				path: '/search/' + encodeURI(this.$refs.search_input.value) + '/'
			})
		},
	},

	mounted() {
		window.addEventListener('keydown', this.keyFocus)
	},

	destroyed() {
		window.removeEventListener('keydown', this.keyFocus)
	},
}
</script>

<style lang="scss" scoped>
.search-bar-container {
	position: relative;
	// max-width: 900px; // BUG on large screens
	margin-top: 3px;
	font-size: 14px;
	font-weight: 300;
	
	@include media-breakpoint-down(xs) {
		display: none;
	}
	
	a {
		@include black_link;
		
		@include underline(#fff, #000);
		
		&:hover {
			@include underline(#fff, $hover-color);
		}
	}
}

.search-bar	{
	color: $input-placeholder-color;
	
	.search-icon {
		position: absolute;
		top: 9px;
		left: 12px;
		cursor: text;

		@include media-breakpoint-down(lg) {
			top: 6px;
			left: 9px;
		}
	}
	
	input {
		width: 100%;
		font-size: 14px;
		margin-bottom: 8px;
		padding: 8px 8px 8px 30px;
		border: solid 1px #c6c6c6;

		@include media-breakpoint-down(lg) {
			padding: 5px 5px 5px 27px;
		}
	}

	.search-help {
		position: absolute;
		top: 11px;
		right: 10px;

		font-size: 12px;
		font-style: italic;
		font-weight: 200;
		white-space: nowrap;
		color: #222;

		@media (hover: none) and (pointer: coarse) {
			display: none;
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}

		svg {
			vertical-align: top;
			// margin-top: 1px;
			cursor: text;
		}
	}

	input:focus + .search-help {
		display: none;
	}
}

.top-searches {
	font-size: 12px;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}
</style>