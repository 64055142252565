<template>
	<div class="container-fluid main-menu">
		<span class="d-none d-sm-inline-flex all-categories" @click="showAllCategoriesMenu()">
			<font-awesome-icon :icon="['fas', 'bars']" class="icon" />
			All categories
		</span>
		
		<span v-for="category in categories" :key="category.url">
			<nuxt-link v-if="category.show_in_main_menu !== false && category.show_in_menu !== false" :to="'/' + category.url + '/'" v-html="category.menu_title" :class="[ category.class, { active: root_category && category.url == root_category.url } ]"></nuxt-link>
		</span>

		<nuxt-link to="/shipping-and-returns/" class="right">Free shipping &amp;&nbsp;returns</nuxt-link>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AccountControls from '~/components/common/AccountControls'

export default
{
	components: {
		AccountControls,
	},
	
	computed: {
		...mapState({
			categories: state => state.categories,
			category: state => state.current_category,
			root_category: state => state.root_category,
			// product: state => state.current_product,
		}),
	},
	
	methods: {
		...mapActions([
			'showAllCategoriesMenu',
		]),
	},
}
</script>

<style lang="scss">
.all-categories {
	color: #fff;
	background: $theme-color;
	flex-grow: 0;
	padding: 10px 18px;
	margin-left: -18px;
	margin-right: 2px;
	border-radius: 20px;
	text-decoration: none;
	cursor: pointer;
	white-space: nowrap;

	display: inline-flex;
	align-items: center;

	@include media-breakpoint-down(lg) {
		padding: 7px 12px;
		margin-left: -12px;
		// margin-left: 0;
	}
	
	@include media-breakpoint-down(xs) {
		border-radius: 9px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
		// margin-left: -18px;
		// padding-left: 16px;
		// padding-bottom: 9px;
		margin-left: 0;
		padding: 10px 12px 11px 16px;
		
		&.on_index {
			// border-top-right-radius: 9px;
		}
		
		.icon {
			font-size: 18px;
			margin: 3px 0;
		}
	}

	&:hover {
		color: #fff;
		background: darken($theme-color, 10%);
		text-decoration: none;
	}

	&.nuxt-link-exact-active,
	&.nuxt-link-active,
	&.active {
		color: #fff;
	}

	.fa, .fas, .far, .fal, .svg-inline--fa {
		margin-right: 7px;
	}
}
</style>

<style lang="scss" scoped>
.main-menu {
	margin-top: 15px;
	font-size: 16px;
	align-items: center;
	display: flex;

	@include media-breakpoint-down(lg) {
		font-size: 14px;
		overflow: hidden;
	}
	
	@include media-breakpoint-down(xs) {
		margin-top: 0;
		// margin-bottom: 5px;
		display: none;
	}

	a {
		color: #000;
		text-decoration: none;
		text-align: center;
		padding: 10px 20px;
		// flex-grow: 1;
		white-space: nowrap;

		@include media-breakpoint-down(lg) {
			padding: 7px 12px;
		}

		@include media-breakpoint-down(sm) {
			padding: 5px 10px;
		}

		&.highlight {
			color: #ea0000 !important;
		}

		&:hover {
			text-decoration: none;
			color: #000;
			background: lighten($theme-color, 55%);
		}

		&.right {
			margin-left: auto;
			
			// TODO: move this to last child?
			margin-right: -20px;
			
			@include media-breakpoint-down(lg) {
				margin-right: -12px;
			}

			@include media-breakpoint-down(sm) {
				margin-right: -10px;
			}
		}

		&.nuxt-link-exact-active,
		&.nuxt-link-active,
		&.active {
			// background: lighten($theme-color, 55%);
			color: $theme-color;
			// border-radius: 20px;

			&:hover {
				// border-radius: 0;
				// background: lighten($theme-color, 50%);
			}
		}
	}
}
</style>