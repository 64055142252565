<template>
<div class="container base-form checkout">
	<nuxt />
</div>
</template>

<script>
import { mapState } from 'vuex'

export default
{
	name: 'checkout',
	
	components: {
	},

	computed: {
	},

	head()
	{
		return {
			bodyAttrs: { class: 'checkout' }
		}
	}
}
</script>

<style lang="scss">
body.checkout {
	background: #a9a9a9;
	
	@include media-breakpoint-down(sm) {
		background: #fff;
	}
}
</style>

<style lang="scss" scoped>
.container {
	max-width: 1000px;
	margin-top: 2%;
	
	@include media-breakpoint-down(sm) {
		max-width: none;
		margin-top: 0;
		padding: 0;
	}
}
</style>