<template>
<div class="body-spacing page-footer">
	<div class="container-fluid">
		
		<div class="row">
			<div class="col-12 col-sm-6">
				<p>Contact&nbsp;us at&nbsp;<a href="mailto:support@homekilo.com">support@homekilo.com</a>, phone <a href="tel:+18333090953">833-309-0953</a> (Monday&mdash;Friday, 9&nbsp;am&ndash;6&nbsp;pm PT).</p>
				<p>Homekilo.com by&nbsp;Card&nbsp;Sapiens&nbsp;LLC, Buffalo, Wyoming<br /><nuxt-link to="/terms/">Terms of&nbsp;service</nuxt-link> &nbsp;&nbsp; <nuxt-link to="/privacy/">Privacy policy</nuxt-link></p>
			</div>
			<div class="col right">
				<img src="/images/payment-icons.png" alt="We accept Visa, Mastercard, American Express, Diners Club, Apple Pay, Google Pay, Microsoft Pay" />
			</div>
		</div>
	</div>
</div>
</template>

<script>

export default
{
	components: {
		
	},
}
</script>

<style lang="scss" scoped>
.page-footer {
	margin-top: 50px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background: #fff;
	border-top: solid 1px $border-color-2;
	font-size: 14px;
	
	p {
		margin-bottom: 0.5rem;
	}
	
	a {
		@include black_link;
		
		@include underline(#fff, #000);
		
		&:hover {
			@include underline(#fff, $hover-color);
		}
	}
	
	.right {
		text-align: right;
		padding-top: 15px;
		
		@include media-breakpoint-down(xs) {
			text-align: left;
		}
		
		img {
			width: 332px;
			height: 25px;
			
			@include media-breakpoint-down(xs) {
				max-width: 100%;
				height: auto;
			}
		}
	}
}
</style>