import { render, staticRenderFns } from "./NotifyMe.vue?vue&type=template&id=2c4e8db4&scoped=true&"
import script from "./NotifyMe.vue?vue&type=script&lang=js&"
export * from "./NotifyMe.vue?vue&type=script&lang=js&"
import style0 from "./NotifyMe.vue?vue&type=style&index=0&id=2c4e8db4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4e8db4",
  null
  
)

export default component.exports