import { mapState, mapActions } from 'vuex'

export default
{
	data: () => ({
		signup_offer_timeout: null,
	}),
	
	computed: {
		...mapState({
			show_signup_offer_notification: state => (state as any).show_signup_offer_notification,
		}),
	},
	
	methods: {
		...mapActions([
			'setShowSignupOfferNotification',
		]),
		
		clearTimeoutToHideSignupOfferNotification()
		{
			if (this.signup_offer_timeout)
				clearTimeout(this.signup_offer_timeout)
		},

		resetTimeoutToHideSignupOfferNotification()
		{
			this.clearTimeoutToHideSignupOfferNotification()

			this.signup_offer_timeout = setTimeout(() =>
			{
				this.setShowSignupOfferNotification(false)

				// Show again in 2 minutes
				setTimeout(() =>
				{
					this.setShowSignupOfferNotification(true)
					this.resetTimeoutToHideSignupOfferNotification()
				}, 120000)

			}, 10000)
		},
	},
	
	mounted()
	{
		setTimeout(() => {
			this.setShowSignupOfferNotification(true)
			this.resetTimeoutToHideSignupOfferNotification()
		}, 2000)
	},
}