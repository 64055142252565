async function getCartByCode($api, code)
{
	try
	{
		const result = await $api.mergeCartByCode({ code: code })
		if (result.status !== 'ok')
			throw 'Failed to get cart from code: ' + result.error
		
		return result
	}
	catch (error)
	{
		console.error(error)
		return null
	}
}

export default async function ({ store, req, $axios, params, $api })
{
	let route_info: {
		product: any,
		category: any,
		sort_mode: any,
		filters: any,
	} = {
		product: null,
		category: null,
		sort_mode: null,
		filters: null,
	}
	// console.log(req.url)

	if (req && req.url && !params.pathMatch)
		params.pathMatch = req.url

	// Home page?
	if (!params.pathMatch || params.pathMatch == '/')
	{
		store.dispatch('setNewRoute', route_info)
		
		if (!store.state.popular_products.length)
		{
			const popular_products_result = await $api.getPopularProducts()
			if (popular_products_result && popular_products_result.status == 'ok')
				store.dispatch('setPopularProducts', popular_products_result.products)
			else
				console.error('Error loading popular products', popular_products_result)
		}
		
		return false
	}
	
	const url_sections = params.pathMatch.split('/').filter((e) => e.length)
	if (url_sections[0] == 'search')
	{
		store.dispatch('setSearchVisible', true)
		
		if (url_sections[1])
			store.dispatch('setSearchQuery', decodeURI(url_sections[1]))
	}
	else
	{
		store.dispatch('setSearchVisible', false)
	}

	if (url_sections[0] == 'cart')
	{
		store.dispatch('showCart')
		if (url_sections[1])
		{
			const result = await getCartByCode($api, url_sections[1])

			if (result)
				store.dispatch('setCustomerCart', result.cart)
			
			store.dispatch('setNewRoute', route_info)
			return false
		}
	}
	
	// if (process.client)
	// {
	// 	const url_sections = params.pathMatch.split('/').filter((e) => e.length)
	// 	if (url_sections.length == 1 && store.state.categories_by_url[url_sections[0]])
	// 	{
	// 		store.dispatch('setNewRoute', {
	// 			url_sections: url_sections,
	// 			product: null,
	// 			category: store.state.categories_by_url[url_sections[0]],
	// 		})
	// 		console.log('rerouted to category on the client side')

	// 		return
	// 	}
	// 	else
	// 		console.log('not a category, routing on the server')
	// }
	
	try
	{
		route_info = await $api.route({ url: params.pathMatch })
		if (!route_info)
			return
	}
	catch (error)
	{
		console.error(error)
		return
	}
	
	// console.log(route_info.filters)
	
	let collectionProductsPromise = null
	let subcategoriesProductsPromise = null
	let countCollectionsProductsPromise = null

	if (route_info.product && (process as any).server)
	{
		// Products will be loaded in Category component after it's activated
	}
	else if (
			route_info.category
		// &&	route_info.category.collection
		&&	(
				!store.state.current_category
			||	route_info.category.url != store.state.current_category.url
			||	route_info.sort_mode != store.state.sort_mode
		)
	)
	{
		// Switched to category
		
		if ((process as any).client)
			window.scrollTo(0, 0)
		
		store.dispatch('setCategoryProducts', null)
		store.dispatch('setLoadingIndicator', { indicator: 'category_products', status: true })
		
		collectionProductsPromise = $api.getCollectionProducts({
			collection: route_info.category.collection,
			sort_mode: route_info.sort_mode,
			filters: route_info.filters,
		}).then((products) => {
			store.dispatch('setCategoryProducts', products)
		})
		
		if (route_info.sort_mode != store.state.sort_mode)
			store.dispatch('setSortMode', route_info.sort_mode)
	}
	else if (route_info.category && !route_info.category.collection)
	{
		store.dispatch('setCategoryProducts', null)
	}
	
	if (route_info.category && route_info.category.subcategories && route_info.category!.subcategories.length)
	{
		subcategoriesProductsPromise = $api.getSubcategoriesProducts({
			category: route_info.category?.url,
		}).then((r: any) =>
		{
			if (r && r.status == 'ok')
				store.dispatch('setSubcategoriesProducts', r.products_by_collection)
			else
				store.dispatch('setSubcategoriesProducts', {})
		})
	}
	
	if (route_info.category && !Object.keys(store.state.collections_product_counts).length)
	{
		countCollectionsProductsPromise = $api.countCollectionsProducts().then((result) =>
		{
			if (result && result.status == 'ok')
				store.dispatch('setCollectionsProductCounts', result.counts)
		})
	}

	if (route_info.product && store.state.current_category_products.length)
	{
		store.dispatch('cacheProduct', { product: route_info.product, reactive: false })
	}
	
	if (route_info)
		store.dispatch('setNewRoute', route_info)
	
	// if (store.$state.current_product.next_product.url == )
	// if (store.state.current_category && store.state.current_product && store.state.current_product.next_product)
		// console.log('/' + store.state.current_category.url + '/' + store.state.current_product.next_product.url + '-' + store.state.current_product.next_product.sku + '/')
		// console.log('matched!', req.url)
	// else
		// console.log(store.state.current_category)
	
	// return $api.route({ url: params.pathMatch }).then((route_info) => {
	// 	store.dispatch('setNewRoute', route_info)
	// })
	if ((process as any).server)
		return Promise.all([collectionProductsPromise, subcategoriesProductsPromise, countCollectionsProductsPromise])
}