<template>
<div class="base-form register-form">
	<h4 class="d-sm-none"><strong>Enter your name and create a&nbsp;password</strong> to&nbsp;get 10%&nbsp;off:</h4>
	<div class="form-field">
		<label for="registration-form-full-name" class="d-none d-sm-block">Full name <span class="comment">for easier checkout</span></label>
		<input type="text" name="full_name" ref="full_name" :class="[ 'full-name-field', { error: error == 'full_name' } ]" id="registration-form-full-name" placeholder="Your first and last name" autocomplete="name" required v-model="full_name" @input="inputFullName" @keydown="keydown" @focus="$emit('focus', 'full_name')" />
	</div>
	<div class="form-field">
		<label for="registration-form-password" class="d-none d-sm-block">New password <span class="comment">8+&nbsp;characters</span></label>
		<input type="hidden" name="login" :value="customer.email.trim().toLowerCase()" />
		<input type="password" name="new-password" ref="password" :class="[ 'password-field', { error: error == 'password' } ]" id="registration-form-password" autocomplete="new-password" required v-model="password" @input="inputPassword" @keydown="keydown" @focus="$emit('focus', 'password')" />
	</div>
	<div class="form-field">
		<span v-if="error" class="error-text">{{ error }}</span>
		<button @click="submit" class="submit" :disabled="!validate() || submitting">
			{{ submitting ? 'Creating account...' : 'Create account' }}
		</button>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import InputToLocalStorage from '~/mixins/InputToLocalStorage'

export default
{
	mixins: [ InputToLocalStorage ],
	
	data: () => ({
		full_name: '',
		password: '',
		error: null,
		submitting: false,
	}),
	
	computed: {
		...mapState({
			customer: state => state.customer,
		}),
	},
	
	methods: {
		...mapActions([
			'updateCustomerData',
		]),
		
		inputFullName(event)
		{
			const full_name = event.target.value.trim()
			
			this.saveToLocalStorage('full_name', full_name)
			
			if (full_name)
				this.updateCustomerData({ full_name: full_name })
			
			if (this.validate())
			{
				this.error = null
			}
		},
		
		inputPassword(event)
		{
			if (this.validate())
			{
				this.error = null
			}
		},
		
		keydown(event)
		{
			if (!event)
				return

			switch (event.keyCode)
			{
				case 13:
					this.submit()
				return
			}
		},
		
		focus()
		{
			if (this.$refs.full_name && !this.$refs.full_name.value)
				this.$refs.full_name.focus()
			else if (this.$refs.password)
				this.$refs.password.focus()
		},
		
		validate()
		{
			return this.full_name && this.password && this.password.length >= 8
		},
		
		async submit()
		{
			if (this.submitting)
				return
			
			if (!this.validate())
			{
				this.error = null
				setTimeout(() => {
					if (this.$refs.full_name.value && this.$refs.password.value.length < 8)
						this.error = 'Please enter password (at least 8 characters)'
					else if (!this.$refs.full_name.value && this.$refs.password.value.length >= 8)
						this.error = 'Please enter your name'
					else
						this.error = 'Please enter correct name and password'
				}, 1)
				
				return
			}
			
			this.error = null
			this.submitting = true
			
			const result = await this.$api.registerCustomer({
				email: this.customer.email.trim().toLowerCase(),
				full_name: this.full_name.trim(),
				password: this.password,
			})
			
			if (!result || (result.status != 'ok' && result.status != 'logged in'))
			{
				this.error = 'Error creating an account. Please try again.'
				this.submitting = false
				return
			}
			
			this.$track.event({
				gtm: {
					category: 'Account',
					action: 'Registration complete',
				},
				fb: {
					event: 'CompleteRegistration',
				}
			})
			
			window.location.reload()
		},
	},
	
	mounted()
	{
		if (!this.full_name && this.customer.full_name)
			this.full_name = this.customer.full_name
		
		if (!this.full_name)
		{
			const e = this.getFromLocalStorage('full_name')
			if (e)
				this.full_name = e
		}
		
		if (!this.full_name)
			this.show_button_on_mobile = false
		
		this.$nextTick(function () {
			this.focus()
		})
		
		this.$track.event({
			gtm: {
				category: 'Account',
				action: 'Registration details form opened',
			},
			fb: {
				event: 'AccountRegistrationDetailsFormOpened',
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.register-form {
	margin: 0 auto;
	padding: 40px 0 60px 0;
	max-width: 314px;
	
	@include media-breakpoint-down(xs) {
		max-width: none;
		padding: 30px 20px 20px 20px;
	}
}

.full-name-field {
	position: relative;
	margin-bottom: 30px;
	
	background: #fff url(/images/user.svg) no-repeat 97% 50%;
	background-position: calc(100% - 10px) 50%;
}

.password-field {
	position: relative;
	margin-bottom: 20px;
	
	background: #fff url(/images/lock.svg) no-repeat 97% 50%;
	background-position: calc(100% - 10px) 50%;
}
</style>