<template>
<Modal @close="this.hideCart" class="no-spacing">

<div class="content cart-block">
	<span class="close-modal" @click="hideCart">&times;</span>
	
	<div class="header">
		<h2>Shopping cart</h2>
	</div>

	<div class="row cart" v-if="cart.length">
		<div class="col-12 col-sm-7 col-lg-8 col-xl-8 col-xxl-9">
			<CartItem v-for="item in cart" :key="item.sku" :item="item" :product="item" /><!-- TODO: key may need to include params for param-specific products (like size, etc) -->

			<div class="subtotals in-table">
				<div class="text-right subtotal" v-if="cart.length > 1">
					Cart subtotal:
					<Currency :price="subtotal" currency="USD" class="price" />
				</div>
				<div class="text-right total_discount" v-if="cart.length > 1 && total_discount">
					You save:
					<Currency :price="total_discount" currency="USD" class="price" />
				</div>
			</div>
		</div>
		<div class="col d-none d-sm-block col-xl-4 col-xxl-3">
			<div class="checkout-section">
				<div class="section subtotals">
					<div class="row original_subtotal" v-if="original_subtotal && original_subtotal > subtotal">
						<div class="col">
							Original price:
						</div>
						<div class="col text-right">
							<Currency :price="original_subtotal" currency="USD" class="price" />
						</div>
					</div>
					
					<div class="row total_discount" v-if="total_discount">
						<div class="col">
							Your discount:
						</div>
						<div class="col text-right">
							&minus;<Currency :price="total_discount" currency="USD" class="price" />
						</div>
					</div>
					
					<div class="row subtotal">
						<div class="col">
							Cart subtotal:
						</div>
						<div class="col text-right">
							<Currency :price="subtotal" currency="USD" class="price" />
						</div>
					</div>

					<nuxt-link to="/checkout/" v-if="subtotal" class="checkout-button" @click.native="hideCart">Checkout <font-awesome-icon :icon="['far', 'chevron-right']" class="icon" /></nuxt-link>
				</div>
				
				<div class="section express-checkouts">
					<!-- <button class="secondary-button apple-pay-button" ref="applePayButton" @click="this.applePay"><font-awesome-icon :icon="['fab', 'apple']" /><strong>Pay</strong></button> -->
					<ExpressCheckoutButton placement="cart" />
					<!-- <button class="secondary-button paypal-button" ref="paypalButton" @click="this.paypal"><strong>PayPal</strong></button> -->
				</div>
			</div>
		</div>
		
		<div class="d-sm-none mobile-checkout-section" v-if="subtotal">
			<div class="row no-gutters blur">
				
				<ExpressCheckoutButton class="col" placement="cart" />
				
				<div class="col">
					<nuxt-link to="/checkout/" class="checkout-button" @click.native="hideCart">Checkout <font-awesome-icon :icon="['far', 'chevron-right']" class="icon" /></nuxt-link>
				</div>
			</div>
		</div>
		
	</div>
	<div v-else class="cart-empty">
		<h3>Your cart is empty</h3>
		<p>Let's find something nice for you</p>
		<p><button @click="startShopping">Start shopping</button></p>
	</div>
</div>

<SavedProductsList v-if="saved.length" class="saved-products" />

<RecommendedProducts v-if="Object.keys(upsell_products) && Object.keys(upsell_products).length" mode="cart" class="recommended-products" />

</Modal>
</template>

<script>
// const _map = require('lodash/map')
import { map as _map } from 'lodash'

import { mapState, mapActions } from 'vuex'

import Modal from '~/components/common/Modal'
import CartItem from '~/components/checkout/CartItem'
import Currency from '~/components/common/Currency'
import SavedProductsList from '~/components/checkout/SavedProductsList'
import RecommendedProducts from '~/components/product/RecommendedProducts'

import ExpressCheckoutButton from '~/components/checkout/ExpressCheckoutButton'

import CartCalculation from '~/mixins/CartCalculation'

export default {
	components: {
		Modal,
		CartItem,
		Currency,
		SavedProductsList,
		RecommendedProducts,
		ExpressCheckoutButton,
	},
	
	mixins: [ CartCalculation ],

	computed: {
		...mapState({
			cart: state => state.customer.cart,
			// cart_products: state => state.cart_products,
			upsell_products: state => state.upsell_products,
			saved: state => state.customer.lists[0].items,
			show_added_to_cart: state => state.show_added_to_cart,
		}),
	},

	methods: {
		...mapActions([
			'hideCart',
			'hideAddedToCart',
			'setUpsellProducts',
		]),

		startShopping()
		{
			// this.$router.push({
			// 	path: '/'
			// })

			this.hideCart()
		},
		
		hideCartIfInCheckout()
		{
			if (this.$nuxt.$route.name == 'checkout')
				this.hideCart()
		},

		applePay()
		{

		},

		paypal()
		{

		},
	},

	mounted()
	{
		if (!this.upsell_products || !Object.keys(this.upsell_products).length)
		{
			this.$api.getUpsellProductsByCart(_map(this.cart, 'sku')).then((upsell_products) => {
				if (upsell_products)// && upsell_products.length) // not an array
					this.setUpsellProducts(upsell_products)
			})
		}
		
		this.$track.event({
			gtm: {
				category: 'Cart',
				action: 'Cart view',
			},
			fb: {
				event: 'CartView',
			},
		})
	},

	destroyed()
	{
		if (this.show_added_to_cart)
			this.hideAddedToCart()
	},
}
</script>

<style lang="scss" scoped>
@keyframes slideInFromBottom {
	from { transform: translate(0, 200px); opacity: 0; }
	to { transform: translate(0); opacity: 1; }
}

.content {
	padding: 15px;

	@include media-breakpoint-down(lg) {
		padding: 5px;
	}

	@include media-breakpoint-down(xs) {
		margin: 0;
		padding: 0;
	}
}


.text-right {
	text-align: right;
}

.header {
	padding: 15px 15px 30px 30px;
	
	@include media-breakpoint-down(lg) {
		padding: 15px 15px 15px 30px;
	}
	
	@include media-breakpoint-down(md) {
		padding: 15px;
	}
}

.cart-block {
	padding-bottom: 60px;
	
	@include media-breakpoint-down(lg) {
		padding-bottom: 20px;
	}
}

.cart {
	padding: 0 30px;
	
	@include media-breakpoint-down(md) {
		padding: 0 15px;
	}
	
	.subtotals {
		margin-top: 10px;
		margin-bottom: 15px;
		font-weight: 500;
		font-size: 15px;
		
		&.in-table {
			padding-right: 21px;
			font-size: 16px;
			
			.price {
				margin-left: 2rem;
			}
		}
	}
	
	.original_subtotal {
		margin-bottom: 5px;
	}

	.subtotal {
		margin-bottom: 5px;
		font-weight: 700;
		font-size: 16px;
		
		.price {
			font-weight: 900;
		}
	}
	
	.total_discount {
		color: $green;
		margin-bottom: 5px;
	}
}

.cart-empty {
	text-align: center;
	padding: 20px;
	margin-bottom: 20px;

	button {
		@include main-button;
	}
}

.section {
	padding: 20px 30px;
}

.checkout-button {
	@include main-button;

	display: block;
	width: 100%;
	margin: 20px 0 30px 0;
	padding-left: 40px;

	.icon {
		font-size: 15px;
		margin-left: 5px;

		animation: horizontal 3s ease infinite
	}
}

.secondary-button {
	@include secondary-button;
	margin: 5px 0 10px 0;
	padding: 10px;
	display: block;
	width: 100%;
	border-radius: 3px;

	&.paypal-button {
		font-style: italic;
	}

	svg {
		vertical-align: -.125em !important;
		margin: 0 2px;
	}
}

.checkout-section {
	padding: 0;
	margin-bottom: 40px;
	border: solid 1px #cfcfcf;
	white-space: nowrap;

	.subtotal {
		padding-right: 0;
	}

	.express-checkouts {
		padding: 35px 55px;
		border-top: solid 1px #cfcfcf;
	}
}

.mobile-checkout-section {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background: #f7f7f7;
	// background: rgba(67, 127, 127, 0.8);
	background: rgba(100, 130, 120, 0.8);
	z-index: 10;
	box-shadow: 0px 0 40px 0px rgba(0, 0, 0, 0.5);
	
	animation: slideInFromBottom 0.2s;
	
	.blur {
		backdrop-filter: blur(7px);
		padding: 20px 10px;
	}
	
	.col {
		padding: 0 5px;
		// background: rgba(255, 255, 0, 0.5);
	}
	
	.checkout-button,
	.secondary-button,
	.payment-request-button {
		margin: 0;
	}
}

h2 {
	margin: 0;
}

.saved-products {
	padding: 30px 45px;
	border-top: solid 10px #ebebeb;
	
	@include media-breakpoint-down(xs) {
		padding: 15px;
	}
}

.recommended-products {
	padding: 30px 45px;
	border-top: solid 10px #ebebeb;
	
	@include media-breakpoint-down(xs) {
		padding: 15px;
	}
}
</style>