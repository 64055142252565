import categories_info from '../components/navigation/categories'

const categories = categories_info.categories
const categories_by_url = categories_info.categories_by_url

export const state = () => ({
	customer: {
		cart: [],
		cart_discounts: [],
		lists: [],
		email: '',
		full_name: '',
		shipping_addresses: [],
		checkout: {
			email: '',
			full_name: '',
			shipping_address: {
				street_address: '',
				unit_number: '',
				city: '',
				state: '',
				zip: '',
				country: '',
				phone: '',
			},
			payment_intent: null,
			tax_rate: 0,
			taxable_part: 0,
			//  TODO: update format in vuex, components and server simultaneously
		},
		submitted_order_number: '',
		submitted_order: {},
		csrf_token: '',
	},

	// cart_products: {},
	lists_products: {},
	upsell_products: {},
	
	categories: categories,
	categories_by_url: categories_by_url,
	current_category: null,
	root_category: null,
	collections_product_counts: {},
	current_category_products: [],
	current_subcategories_products: {},
	current_filters: [],
	current_product: null,
	current_category_total: 0,
	popular_products: [],

	loading_indicators: {
		category_products: true,
		search_results: true,
	},

	show_added_to_cart: false,
	show_cart: false,
	show_saved_for_later: false,
	show_notify_me: false,
	show_all_categories_menu: false,
	show_registration_form: false,
	show_signup_offer_notification: false,
	
	sort_mode: 'recommended',
	display_mode: 'small',

	modals_count: 0,
	
	started_locating_customer: false,
	
	search: {
		query: null,
		results: {},
		visible: false,
	},
})

export const getters = {
	// root_category: state => {
	// 	if (!state.current_category)
	// 		return null
		
	// 	if (state.current_category.parents)
	// 		return state.categories_by_url[state.current_category.parents[0]]
	// 	else
	// 		return state.current_category
	// }
}

export const mutations = {
	setCurrentProduct: (state, product) => {
		state.current_product = product
	},

	setCurrentCategory: (state, category) => {
		state.current_category = category
	},

	setRootCategory: (state, root_category) => {
		state.root_category = root_category
	},

	setCurrentCategoryProducts: (state, products) => {
		state.current_category_products = products ? products.products : []
		state.current_category_total = products ? products.result.total : 0
	},

	setLoadingIndicator: (state, {indicator, status}) => {
		state.loading_indicators[indicator] = status
	},

	cacheProduct: (state, { product, index, reactive }) => {
		if (state.search.visible)
		{
			state.search.results.products[index] = product
			
			if (reactive)
				state.search.results.products = [...state.search.results.products]
		}
		else
		{
			state.current_category_products[index] = product
			
			if (reactive)
				state.current_category_products = [...state.current_category_products]
		}
	},
	
	setCustomer: (state, customer) =>
	{
		state.customer = customer
	},

	setCustomerCart: (state, cart) => {
		state.customer.cart = cart
	},

	// setCartProducts: (state, cart_products) => {
	// 	state.cart_products = cart_products
	// },
	
	setListsProducts: (state, lists_products) =>
	{
		state.lists_products = lists_products
	},

	updateShowAddedToCart: (state, param) => {
		state.show_added_to_cart = param
	},

	updateShowCart: (state, param) => {
		state.show_cart = param
	},
	
	updateShowSavedForLater: (state, param) =>
	{
		state.show_saved_for_later = param
	},

	updateModalsCount: (state, count) => {
		state.modals_count = count
	},

	setCustomerLists: (state, lists) => {
		state.customer.lists = lists
	},

	setUpsellProducts: (state, upsell_products) => {
		state.upsell_products = upsell_products
	},
	
	setCustomerParam: (state, param) => {
		state.customer[param.name] = param.value
	},
	
	setCheckoutParam: (state, param) =>
	{
		state.customer.checkout[param.name] = param.value
	},
	
	setCheckout: (state, param) =>
	{
		state.customer.checkout = param
	},
	
	setCheckoutShippingAddress: (state, param) =>
	{
		state.customer.checkout.shipping_address = param
	},
	
	setCustomerCsrfToken: (state, param) =>
	{
		state.customer.csrf_token = param
	},
	
	setCollectionsProductCounts: (state, param) =>
	{
		state.collections_product_counts = param
	},
	
	setShowNotifyMe: (state, param) =>
	{
		state.show_notify_me = param
	},
	
	setSortMode: (state, param) =>
	{
		state.sort_mode = param
	},
	
	setDisplayMode: (state, param) =>
	{
		state.display_mode = param
	},
	
	setSearchQuery: (state, param) =>
	{
		state.search.query = param
	},
	
	setSearchResults: (state, param) =>
	{
		state.search.results = param
	},
	
	setSearchVisible: (state, param) =>
	{
		state.search.visible = param
	},
	
	setShowAllCategoriesMenu: (state, param) =>
	{
		state.show_all_categories_menu = param
	},
	
	setPopularProducts: (state, param) =>
	{
		state.popular_products = param
	},
	
	setSubmittedOrderNumber: (state, param) =>
	{
		state.customer.submitted_order_number = param
	},
	
	setSubmittedOrder: (state, param) =>
	{
		state.customer.submitted_order = param
	},
	
	setSubcategoriesProducts: (state, param) =>
	{
		state.current_subcategories_products = param
	},
	
	setStartedLocatingCustomer: (state, param) =>
	{
		state.started_locating_customer = param
	},
	
	setShowRegistrationForm: (state, param) =>
	{
		state.show_registration_form = param
	},
	
	setCustomerCartDiscounts: (state, param) =>
	{
		state.customer.cart_discounts = param
	},
	
	setShowSignupOfferNotification: (state, param) =>
	{
		state.show_signup_offer_notification = param
	},
}

export const actions = {
	
	async nuxtServerInit({ commit, state }, { req, $api })
	{
		if (req.customer)
		{
			const public_customer_keys = [
				'_id', // needed for page reload on server updates if logged in
				'is_registered',
				'email',
				'phone',
				'full_name',
				'shipping_addresses',
				'cart',
				'lists',
				'discounts',
			]
			
			for (const key of public_customer_keys)
			{
				commit('setCustomerParam', {
					name: key,
					value: req.customer[key],
				})
			}
			
			commit('setCustomerCart', req.customer.cart || [])
			commit('setCustomerCsrfToken', req.session.csrf.token || '')

			if (req.customer.lists && req.customer.lists.length)
			{
				commit('setCustomerLists', req.customer.lists)
			}
			else
			{
				commit('setCustomerLists', [ { name: 'saved', items: [] } ])
			}
			
			commit('setCustomerParam', {
				name: 'shipping_addresses',
				value: req.customer.shipping_addresses,
			})
			
			// Set customer for server-side API
			$api.setCustomer(req.customer) // TODO: move to auth serverMiddleware?
			
			if (req.customer.cart.length)
			{
				// const cart_products = await $api.getCartProducts()
				// commit('setCartProducts', cart_products)
				const cart_data = await $api.getCartData(req.customer.cart)
				commit('setCustomerCart', cart_data.cart)
				commit('setCustomerCartDiscounts', cart_data.discounts)

				// const _map = require('lodash').map
				// const upsell_products = await $api.getUpsellProductsByCart(_map(req.customer.cart, 'sku'))
				// commit('setUpsellProducts', upsell_products)
			}
			
			if (req.customer.lists.length)
			{
				const lists_products = await $api.getListsProducts()
				commit('setListsProducts', lists_products)
			}
			
			if (req.customer.checkout)
			{
				commit('setCheckout', req.customer.checkout)
			}
			
			if (!state.customer.checkout.full_name && req.customer.full_name)
				commit('setCheckoutParam', { name: 'full_name', value: req.customer.full_name })
			if (!state.customer.checkout.email && req.customer.email)
				commit('setCheckoutParam', { name: 'email', value: req.customer.email })
		}
	},

	setNewRoute({commit, state}, route_info)
	{
		// if (!route_info.product || !state.current_product || state.current_product.sku != route_info.product.sku)
			commit('setCurrentProduct', route_info.product)
		
		if (!route_info.category || !state.current_category || state.current_category.url != route_info.category.url)
		{
			commit('setCurrentCategory', route_info.category)
			
			if (route_info.category && route_info.category.parents.length)
				commit('setRootCategory', state.categories_by_url[route_info.category.parents[0]])
			else
				commit('setRootCategory', route_info.category)
			
			if (route_info.sort_mode)
				commit('setSortMode', route_info.sort_mode)
		}
	},

	setCurrentProduct({ commit, state }, product)
	{
		commit('setCurrentProduct', product)
	},

	setCategoryProducts({ commit, state }, products)
	{
		commit('setCurrentCategoryProducts', products)
		commit('setLoadingIndicator', { indicator: 'category_products', status: false })
	},

	cacheProduct({ commit, state }, { product, reactive })
	{
		let products = state.current_category_products
		if (state.search.visible)
			products = state.search.results.products
		
		if (!products)
			return false
		
		const product_index = products.findIndex(p => {
			return p.sku === product.sku
		})
		
		if (product_index != -1)
			commit('cacheProduct', { product: product, index: product_index, reactive })
	},

	setLoadingIndicator({ commit, state }, { indicator, status })
	{
		commit('setLoadingIndicator', { indicator: indicator, status: status })
	},
	
	setCustomer({ commit, state }, customer)
	{
		commit('setCustomer', customer)
	},

	setCustomerCart({ commit, state }, cart)
	{
		commit('setCustomerCart', cart)
	},

	// setCartProducts({ commit, state }, cart_products)
	// {
	// 	commit('setCartProducts', cart_products)
	// },
	
	setListsProducts({ commit, state }, lists_products)
	{
		commit('setListsProducts', lists_products)
	},

	showAddedToCart({ commit, state }, params) {
		commit('updateShowAddedToCart', true)
	},

	hideAddedToCart({ commit, state }, params) {
		commit('updateShowAddedToCart', false)
	},

	showCart({ commit, state }, params) {
		commit('updateShowCart', true)
	},

	hideCart({ commit, state }, params) {
		commit('updateShowCart', false)
	},
	
	showSavedForLater({ commit, state }, params)
	{
		commit('updateShowSavedForLater', true)
	},

	hideSavedForLater({ commit, state }, params)
	{
		commit('updateShowSavedForLater', false)
	},

	updateModalsCount({ commit, state }, count) {
		commit('updateModalsCount', count)
	},

	applyServerUpdates({ commit, state }, updates)
	{
		// console.log(updates)
		commit('setCustomerCart', updates.cart)
		// commit('setCartProducts', updates.cart_products)
		
		// TODO: check if customer id has changed, show message / reload page
	},

	setCustomerLists({ commit, state }, lists)
	{
		// console.log(lists)
		commit('setCustomerLists', lists)
	},

	setUpsellProducts({ commit, state }, upsell_products)
	{
		// console.log(upsell_products)
		commit('setUpsellProducts', upsell_products)
	},
	
	
	updateCustomerData({ commit, state }, params)
	{
		for (const [key, value] of Object.entries(params))
		{
			commit('setCustomerParam', { name: key, value: value })
		}
	},
	
	setCheckout({ commit, state }, checkout)
	{
		commit('setCheckout', checkout)
	},
	
	updateCheckoutData({ commit, state }, params)
	{
		for (const [key, value] of Object.entries(params))
		{
			commit('setCheckoutParam', { name: key, value: value })
		}
	},
	
	setCheckoutShippingAddress({ commit, state }, address)
	{
		commit('setCheckoutShippingAddress', address)
	},
	
	setCollectionsProductCounts({ commit, state }, counts)
	{
		commit('setCollectionsProductCounts', counts)
	},
	
	showNotifyMe({ commit, state }, params)
	{
		commit('setShowNotifyMe', true)
	},
	
	hideNotifyMe({ commit, state }, params)
	{
		commit('setShowNotifyMe', false)
	},
	
	setSortMode({ commit, state }, mode)
	{
		commit('setSortMode', mode)
	},
	
	setDisplayMode({ commit, state }, mode)
	{
		commit('setDisplayMode', mode)
	},
	
	setSearchQuery({ commit, state }, param)
	{
		commit('setSearchQuery', param)
	},
	
	setSearchResults({ commit, state }, param)
	{
		commit('setSearchResults', param)
	},
	
	setSearchVisible({ commit, state }, param)
	{
		commit('setSearchVisible', param)
	},
	
	showAllCategoriesMenu({ commit, state }, params)
	{
		commit('setShowAllCategoriesMenu', true)
	},

	hideAllCategoriesMenu({ commit, state }, params)
	{
		commit('setShowAllCategoriesMenu', false)
	},
	
	setPopularProducts({ commit, state }, products)
	{
		commit('setPopularProducts', products)
	},
	
	setSubmittedOrderNumber({ commit, state }, number)
	{
		commit('setSubmittedOrderNumber', number)
	},
	
	setSubmittedOrder({ commit, state }, order)
	{
		commit('setSubmittedOrder', order)
	},
	
	setSubcategoriesProducts({ commit, state }, products)
	{
		commit('setSubcategoriesProducts', products)
	},
	
	startedLocatingCustomer({ commit, state }, params)
	{
		commit('setStartedLocatingCustomer', true)
	},
	
	showRegistrationForm({ commit, state }, params)
	{
		commit('setShowRegistrationForm', true)
	},
	
	hideRegistrationForm({ commit, state }, params)
	{
		commit('setShowRegistrationForm', false)
	},
	
	setCustomerCartDiscounts({ commit, state }, param)
	{
		commit('setCustomerCartDiscounts', param)
	},
	
	setShowSignupOfferNotification({ commit, state }, param)
	{
		commit('setShowSignupOfferNotification', param)
	},
}