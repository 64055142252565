<template>
<span class='header-link' @click="showCart">
	<span :class="{ 'not-empty': cart.length }">
		<font-awesome-icon :icon="['fas', 'shopping-cart']" class="d-none d-sm-inline-block" />
		<font-awesome-icon :icon="['far', 'shopping-cart']" class="d-sm-none" />
		<span class="d-none d-xl-inline">Shopping&nbsp;cart</span>
		<span :class="[ { 'd-none': true }, 'd-sm-inline', 'd-xl-none' ]">Cart</span>
		<span class="count" v-if="cart.length">{{ cart.length }}</span>
	</span>
</span>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	computed: {
		...mapState({
			cart: state => state.customer.cart,
		}),
	},

	methods: {
		...mapActions([
			'showCart'
		]),
	},
}
</script>
<style lang="scss" scoped>
@keyframes animated-background {
	0% { background: #e7f4ff; }
	50% { background: #bee1ff; }
	100% { background: #e7f4ff; }
}

.header-link {
	@include media-breakpoint-down(xs) {
		// padding-right: 12% !important;
		
		span {
			margin-left: -3px;
		}
	}
	
	@include media-breakpoint-down(xxs) {
		padding-right: 10% !important;
	}
}

.not-empty {
	padding: 6px 7px;
	border: solid 1px #0088ff;
	border-radius: 15px;
	background: #e7f4ff;
	animation: animated-background 3s infinite;
	
	@include media-breakpoint-down(xs) {
		padding: 7px 8px 5px 8px;
		border-radius: 17px;
	}
}

.count {
	position: relative;
	top: -1px;
	margin-left: 1px;
	padding: 1px 5px;
	color: #fff;
	background: #ea0000;
	border-radius: 20px;
	font-size: 11px;
	font-weight: bold;
	
	@include media-breakpoint-down(xs) {
		top: -2px;
		left: 2px;
	}
}
</style>