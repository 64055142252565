import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Importing all icons for debug (disable in production):
// import { brands } from '@fortawesome/fontawesome-free-brands'
// import { fas } from '@fortawesome/pro-solid-svg-icons'
// import { far } from '@fortawesome/pro-regular-svg-icons'
// import { fal } from '@fortawesome/pro-light-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// Importing specific icons to keep bundle size down
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons/faHeart'
import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons/faHeart'
import { faShoppingCart as fasShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart'
import { faShoppingCart as farShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart'
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faApple as fabApple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft as falChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faTh as fasTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faThLarge as fasThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge'
import { faLockAlt as farLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt'
import { faBadgePercent as fasBadgePercent } from '@fortawesome/pro-solid-svg-icons/faBadgePercent'

library.add(
	farSearch,
	fasUser,
	farUser,
	fasHeart,
	farHeart,
	fasShoppingCart,
	farShoppingCart,
	fasBars,
	fabApple,
	fabFacebook,
	farCheck,
	farCheckCircle,
	fasCaretDown,
	farChevronRight,
	fasChevronRight,
	falChevronLeft,
	farChevronLeft,
	fasTh,
	fasThLarge,
	farLockAlt,
	fasBadgePercent,
)

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
// library.add(brands)
// library.add(fas, far, fal)

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.performance = true