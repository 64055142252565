export default (context, inject) =>
{
	window.OneSignal = window.OneSignal || []
	OneSignal.push(function()
	{
		OneSignal.init({
			appId: "dc7d4cde-3d6d-4cf1-8dbb-44ee5373e9b7",
		})
	})
	
	if (context.store.state.customer._id)
	{
		OneSignal.push(function()
		{
			OneSignal.setExternalUserId(context.store.state.customer._id)
		})
	}
	
	if (context.store.state.customer.email)
	{
		OneSignal.push(function()
		{
			OneSignal.setEmail(context.store.state.customer.email)
		})
	}
	
	context.$onesignal = OneSignal
	inject('onesignal', context.$onesignal)
}