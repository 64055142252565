<template>
<div>
	<Header :class="{ invisible: modals_count && product && !show_cart && !show_saved_for_later }" />
	<MobileHeader :class="{ invisible: modals_count && product && !show_cart && !show_saved_for_later }" />
	
	<nuxt-child name="product" v-if="product" :key="product.sku" />
	<nuxt-child name="category" keep-alive v-if="category" :key="category.url" />
	<nuxt-child name="search" keep-alive v-if="search_visible" />
	<nuxt />
	
	<Footer :class="{ invisible: modals_count && product && !show_cart && !show_saved_for_later }" />
	
	<Cart keep-alive v-if="show_cart" />
	<SavedForLater keep-alive v-if="show_saved_for_later" />
	<NotifyMe keep-alive v-if="show_notify_me && product && !product.available" :product="product" />
	<AllCategoriesMenu v-if="show_all_categories_menu" />
	<RegistrationForm v-if="show_registration_form" />
</div>
</template>

<script>
import { mapState } from 'vuex'

import Header from '~/components/common/Header'
import MobileHeader from '~/components/common/MobileHeader'
import Footer from '~/components/common/Footer'
import Cart from '~/components/checkout/Cart'
import SavedForLater from '~/components/checkout/SavedForLater'
import NotifyMe from '~/components/product/NotifyMe'
import AllCategoriesMenu from '~/components/navigation/AllCategoriesMenu'
import RegistrationForm from '~/components/signup/RegistrationForm'

import SignupOfferNotification from '~/mixins/SignupOfferNotification'

export default
{
	name: 'default',
	
	components: {
		Header,
		MobileHeader,
		Footer,
		Cart,
		SavedForLater,
		NotifyMe,
		AllCategoriesMenu,
		RegistrationForm,
	},
	
	mixins: [ SignupOfferNotification ],

	data: function()
	{
		return {
			serverDataExchangeTimeoutId: null,
		}
	},

	computed: {
		...mapState({
			category: state => state.current_category,
			product: state => state.current_product,
			customer: state => state.customer,
			search_visible: state => state.search.visible,
			show_cart: state => state.show_cart,
			show_saved_for_later: state => state.show_saved_for_later,
			show_notify_me: state => state.show_notify_me,
			show_all_categories_menu: state => state.show_all_categories_menu,
			modals_count: state => state.modals_count,
			show_registration_form: state => state.show_registration_form,
		}),
	},

	methods: {
		scheduleServerDataExchange()
		{
			// TODO: switch to websockets? add last updated time tracking to reduce traffic
			this.serverDataExchangeTimeoutId = setTimeout(async () => {
				const result = await this.$api.getServerUpdates()
				if (result && result.status == 'ok')
				{
					if (result.customer && result.customer.id && result.customer.id != this.customer._id)
					{
						console.error('Incorrect customer id after server data exchange, reloading page...')
						window.location.reload()
					}
					
					// TODO: compare before updating, most of the time nothing has changed
					this.$store.dispatch('applyServerUpdates', result)
				}
				// else
				// 	console.error(result)

				this.scheduleServerDataExchange()
			}, 10000)
		}
	},

	mounted()
	{
		if (process.env.NODE_ENV == 'production')
			this.scheduleServerDataExchange()
	},

	destroyed()
	{
		clearTimeout(this.serverDataExchangeTimeoutId)
	},
}
</script>

<style lang="scss" scoped>
.invisible {
	visibility: hidden;
}
</style>