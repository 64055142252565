<template>
<div style="recommended-products">
	<h2 v-if="mode == 'product'">You may also need</h2>
	<h2 v-if="mode == 'cart' && cart.length">You may also like</h2>
	<h2 v-if="mode == 'saved'">You may also need</h2>
	<div class="row">
		<ProductItem v-for="product in recommended_products" :key="product.sku" :product="product" class="col-6 col-md-4 col-xxl-3" @click.native="click" />
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ProductItem from '~/components/product/ProductItem'

export default {
	components: {
		ProductItem,
	},

	props: {
		product: Object,
		mode: { type: String, default: 'product' }
	},

	computed: {
		...mapState({
			upsell_products: state => state.upsell_products,
			show_added_to_cart: state => state.show_added_to_cart,
			show_cart: state => state.show_cart,
			show_saved_for_later: state => state.show_saved_for_later,
			cart: state => state.customer.cart,
		}),

		recommended_products: function()
		{
			switch (this.mode)
			{
				case 'product':
				return this.upsell_products[this.product.sku]

				case 'cart':
				case 'saved':
					let result = []
					for (const sku of Object.values(this.upsell_products))
						for (const p of Object.values(sku))
							// result[p.sku] = p
							result.push(p)
				return result.slice(0, 12)
			}
		},
	},

	methods: {
		...mapActions([
			'hideCart',
			'hideAddedToCart',
			'hideSavedForLater',
		]),

		click()
		{
			if (this.show_added_to_cart)
				this.hideAddedToCart()
			if (this.show_cart)
				this.hideCart()
			if (this.show_saved_for_later)
				this.hideSavedForLater()
		},
	},
}
</script>

<style lang="scss" scoped>
.recommended-products {
	min-height: 400px;
}

h2 {
	margin-bottom: 30px;
}
</style>