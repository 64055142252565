const GA_ID = 'UA-149349808-1'
const GA4_ID = 'G-QB1PGWRF9J'
const YM_ID = 64876624

const track = {
	installed: false,
	
	async event(params)
	{
		this.ga_event(params)
		this.ecom_event(params)
		this.fb_event(params)
	},
	
	ga_event(params)
	{
		if (!params.gtm || !this.gtag)
			return

		window.dataLayer = window.dataLayer || []
		
		this.gtag('event', params.gtm.action || '', {
			event_category: params.gtm.category || '',
			event_label: params.gtm.label || '',
			value: params.gtm.value || 0,
		})
	},
	
	gtm_event(params)
	{
		if (!params.gtm)
			return
		
		window.dataLayer = window.dataLayer || []
		
		window.dataLayer.push({
			'event': 'customEvent',
			'event-category': params.gtm.category || '',
			'event-action': params.gtm.action || '',
			'event-label': params.gtm.label || '',
			'event-value': params.gtm.value || 0,
		})
	},
	
	ecom_event(params)
	{
		if (!params.ecom)
			return

		window.dataLayer = window.dataLayer || []
		
		dataLayer.push({
			event: 'ee-event',
			ecommerce: params.ecom,
		})
	},
	
	fb_event(params)
	{
		if (typeof fbq != 'function' || !params.fb)
			return
		
		const standard_events = [
			'AddPaymentInfo',
			'AddToCart',
			'AddToWishlist',
			'CompleteRegistration',
			'Contact',
			'CustomizeProduct',
			'Donate',
			'FindLocation',
			'InitiateCheckout',
			'Lead',
			'Purchase',
			'Schedule',
			'Search',
			'StartTrial',
			'SubmitApplication',
			'Subscribe',
			'ViewContent',
		]
		
		if (standard_events.includes(params.fb.event))
			fbq('track', params.fb.event, params.fb)
		else
			fbq('trackCustom', params.fb.event, params.fb)
	},
	
	setup_gtm(context)
	{
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-WNNF5L5');
	},
	
	setup_ga(context)
	{
		let script = document.createElement('script')
		script.setAttribute('async', 'async')
		script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + GA_ID)
		document.body.appendChild(script)
		
		window.dataLayer = window.dataLayer || [];
		function gtag() { dataLayer.push(arguments); }
		gtag('js', new Date());
		gtag('config', GA_ID);
		this.gtag = gtag
		
		if (context.store.state.customer && context.store.state.customer._id)
		{
			gtag('set', { 'user_id': context.store.state.customer._id })
		}
	},
	
	setup_fb(context)
	{
		!function(f,b,e,v,n,t,s)
		{if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
		n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
		if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;s=b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t,s)}(window, document,'script',
		'https://connect.facebook.net/en_US/fbevents.js');
		fbq('init', '503878257060840')
		
		this.fbq = fbq
	},
	
	setup_yandex(context)
	{
		(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
		m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
		(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

		ym(YM_ID, "init", {
			clickmap:true,
			trackLinks:true,
			accurateTrackBounce:true,
			webvisor:true,
			ecommerce:"dataLayer"
		});
		
		this.ym = ym
	},
	
	pageview(to, from, context)
	{
		if (!this.gtag)
			return
		
		this.gtag('config', GA_ID, { 'page_path': to.fullPath })
		this.fbq('track', 'PageView')
		this.ym(YM_ID, 'hit', to.fullPath, {})
	},
	
	init(context)
	{
		this.$context = context
		
		if (process.env.NODE_ENV != 'production' || typeof fbq === 'function')
			return
		
		this.setup_ga(context)
		this.setup_fb(context)
		this.setup_yandex(context)
		
		context.app.router.afterEach((to, from) =>
		{
			// Ignore first page load (tracked automatically on setup)
			if (!from || from.name === null)
				return
			
			setTimeout(() => {
				this.pageview(to, from, context)
			}, 200)
		})
		
		this.installed = true
	},
}

export default (context, inject) =>
{
	if (context.$track || track.installed)
		return false
	
	track.init(context)
	context.$track = track
	inject('track', context.$track)
}