<template>
<div class="base-form login-form">
	<h4 class="d-sm-none"><strong>Enter your password</strong> for {{ customer.email }}:</h4>
	<div class="form-field">
		<label for="login-form-password" class="d-none d-sm-block">
			Your password
		</label>
		<input type="hidden" name="login" :value="customer.email.trim().toLowerCase()" />
		<input type="password" name="password" ref="password" :class="[ 'password-field', { error: error == 'password' } ]" id="login-form-password" autocomplete="password" required v-model="password" @input="input" @keydown="keydown" @focus="$emit('focus', 'password')" />
	</div>
	<div class="form-field">
		<span v-if="error && !wrong_password" class="error-text">{{ error }}</span>
		<span v-if="wrong_password" class="error-text">
			Wrong password. Please enter the&nbsp;correct password or&nbsp;<span class="request-login-link">request a&nbsp;login link</span>.
		</span>
		<button @click="submit" class="submit" :disabled="!validate() || submitting">
			{{ submitting ? 'Signing in...' : 'Sign in' }}
		</button>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default
{
	data: () => ({
		error: null,
		show_button_on_mobile: true,
		submitting: false,
		wrong_password: false,
		password: '',
	}),
	
	computed: {
		...mapState({
			customer: state => state.customer,
		}),
	},
	
	methods: {
		...mapActions([
			
		]),
		
		input(event)
		{
			const password = event.target.value
			
			if (password)
				this.show_button_on_mobile = true
			else
				this.show_button_on_mobile = false
			
			if (this.validate())
			{
				this.error = null
			}
		},
		
		keydown(event)
		{
			if (!event)
				return

			switch (event.keyCode)
			{
				case 13:
					this.submit()
				return
			}
		},
		
		focus()
		{
			if (this.$refs.password)
				this.$refs.password.focus()
		},
		
		validate()
		{
			return this.password ? true : false
		},
		
		async submit()
		{
			if (this.submitting)
				return
			
			if (!this.validate())
			{
				this.error = null
				setTimeout(() => {
					this.error = 'Please enter your password to continue'
				}, 1)
				
				return
			}
			
			this.error = null
			this.submitting = true
			
			const result = await this.$api.loginCustomer({
				email: this.customer.email.trim().toLowerCase(),
				password: this.password,
			})
			
			if (result && result.status == 'error' && result.error == 'wrong password')
			{
				this.wrong_password = true
				this.error = 'Wrong password. Please enter the correct password or request a login link by clicking the button below.'
				this.submitting = false
				return
			}
			
			this.wrong_password = false
			
			if (!result || (result.status != 'ok' && result.status != 'logged in'))
			{
				this.error = 'Error: could not sign in to your account. Please try again.'
				this.submitting = false
				return
			}
			
			this.$track.event({
				gtm: {
					category: 'Account',
					action: 'Login',
				},
				fb: {
					event: 'AccountLogin',
				}
			})
			
			window.location.reload()
		},
	},
	
	mounted()
	{
		if (!this.password)
			this.show_button_on_mobile = false
		
		this.$nextTick(function () {
			this.focus()
		})
		
		this.$track.event({
			gtm: {
				category: 'Account',
				action: 'Password form opened',
			},
			fb: {
				event: 'AccountPasswordFormOpened',
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.login-form {
	margin: 0 auto;
	padding: 40px 0 60px 0;
	max-width: 314px;
	
	@include media-breakpoint-down(xs) {
		max-width: none;
		padding: 30px 20px 20px 20px;
	}
}

.password-field {
	position: relative;
	margin-bottom: 0;
	
	background: #fff url(/images/lock.svg) no-repeat 97% 50%;
	background-position: calc(100% - 10px) 50%;
}

.request-login-link {
	cursor: pointer;
	text-decoration: underline;
	color: #000;
	
	&:hover {
		color: $hover-color;
	}
}
</style>