<template>
<div class="mobile-header">
	<div class="row no-gutters">
		<div class="col-4 col-xs-5 left">
			<span :class="[ 'all-categories', { on_index: is_index } ]" @click="showAllCategoriesMenu()">
				<font-awesome-icon :icon="['fas', 'bars']" class="icon" />
				<span class="d-none d-xs-inline">All categories</span>
			</span>
		</div>
		<div class="col-8 col-xs-7 right">
			<AccountControls />
		</div>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Logo from '~/components/common/Logo'
import SearchBar from '~/components/search/SearchBar'
import AccountControls from '~/components/common/AccountControls'
import MainMenu from '~/components/navigation/MainMenu'

export default
{
	components: {
		Logo,
		SearchBar,
		AccountControls,
		MainMenu,
	},
	
	computed: {
		
		is_index()
		{
			return this.$nuxt.$route.name == 'index'
		},
		
	},
	
	methods: {
		...mapActions([
			'showAllCategoriesMenu',
		]),
	},
}
</script>

<style lang="scss">
// <style lang="scss" scoped>
.mobile-header {
	display: none;
	
	// &.header {
	// 	display: block;
	// }
	
	@include media-breakpoint-down(xs) {
		display: block;
		position: sticky;
		background: #fff;
		top: 0;
		z-index: 10;
		padding: 0;
		box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.15);
	}
	
	.right {
		
		@include media-breakpoint-down(xs) {
			padding: 0;
		}
	}
}
</style>