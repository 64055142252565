import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _94494e96 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _162df7fe = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _fb1db9b0 = () => interopDefault(import('../pages/main.vue' /* webpackChunkName: "pages/main" */))
const _07d6ded8 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _4c150f69 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _2d737f23 = () => interopDefault(import('../pages/privacy-terms.vue' /* webpackChunkName: "pages/privacy-terms" */))
const _5b9532c6 = () => interopDefault(import('../pages/shipping-and-returns.vue' /* webpackChunkName: "pages/shipping-and-returns" */))
const _5725dc28 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _2c69a9ec = () => interopDefault(import('../pages/cart/_slug.vue' /* webpackChunkName: "pages/cart/_slug" */))
const _a5afc41a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _71c93890 = () => interopDefault(import('../pages/main' /* webpackChunkName: "" */))
const _273144c2 = () => interopDefault(import('../components/category/Category' /* webpackChunkName: "components/category/Category" */))
const _120773ba = () => interopDefault(import('../components/product/Product.vue' /* webpackChunkName: "components/product/Product" */))
const _a6d78a28 = () => interopDefault(import('../components/search/SearchResults.vue' /* webpackChunkName: "components/search/SearchResults" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _94494e96,
    name: "checkout"
  }, {
    path: "/contact",
    component: _162df7fe,
    name: "contact"
  }, {
    path: "/main",
    component: _fb1db9b0,
    name: "main"
  }, {
    path: "/orders",
    component: _07d6ded8,
    name: "orders"
  }, {
    path: "/privacy",
    component: _4c150f69,
    name: "privacy"
  }, {
    path: "/privacy-terms",
    component: _2d737f23,
    name: "privacy-terms"
  }, {
    path: "/shipping-and-returns",
    component: _5b9532c6,
    name: "shipping-and-returns"
  }, {
    path: "/terms",
    component: _5725dc28,
    name: "terms"
  }, {
    path: "/cart/:slug?",
    component: _2c69a9ec,
    name: "cart-slug"
  }, {
    path: "/",
    component: _a5afc41a,
    name: "index"
  }, {
    path: "*",
    components: {
      default: _71c93890,
      category: _273144c2,
      product: _120773ba,
      search: _a6d78a28
    }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
