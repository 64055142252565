<template>
<Modal @close="this.hideSavedForLater">

<div class="header right">
	<span class="close-modal" @click="hideSavedForLater">&times;</span>
</div>

<SavedProductsList v-if="saved.length" class="saved-products" />
<div v-else class="saved-empty">
	<h3>You don't have any saved products</h3>
	<p>Let's find something nice for you</p>
	<p><button @click="startShopping">Start shopping</button></p>
</div>


<RecommendedProducts v-if="Object.keys(upsell_products).length" mode="saved" class="recommended-products" />

</Modal>
</template>

<script>
import { map as _map } from 'lodash'

import { mapState, mapActions } from 'vuex'

import Modal from '~/components/common/Modal'
import CartItem from '~/components/checkout/CartItem'
import Currency from '~/components/common/Currency'
import SavedProductsList from '~/components/checkout/SavedProductsList'
import RecommendedProducts from '~/components/product/RecommendedProducts'

import ExpressCheckoutButton from '~/components/checkout/ExpressCheckoutButton'

import CartCalculation from '~/mixins/CartCalculation'

export default {
	components: {
		Modal,
		SavedProductsList,
		RecommendedProducts,
	},

	computed: {
		...mapState({
			upsell_products: state => state.upsell_products,
			saved: state => state.customer.lists[0].items,
			show_added_to_cart: state => state.show_added_to_cart,
		}),
	},

	methods: {
		...mapActions([
			'hideCart',
			'hideSavedForLater',
			'hideAddedToCart',
			'setUpsellProducts',
		]),

		startShopping()
		{
			this.hideSavedForLater()
		},
	},

	mounted()
	{
		if (!Object.keys(this.upsell_products).length)
		{
			this.$api.getUpsellProductsByCart(_map(this.cart, 'sku')).then((upsell_products) => {
				this.setUpsellProducts(upsell_products)
			})
		}
	},

	destroyed()
	{
	},
}
</script>

<style lang="scss" scoped>
.right {
	float: right;
}

.saved-products {
	padding: 30px 45px;
	
	@include media-breakpoint-down(xs) {
		padding: 15px;
	}
}

.saved-empty {
	text-align: center;
	padding: 40px 0 20px 0;
	margin-bottom: 20px;

	button {
		@include main-button;
	}
}

.recommended-products {
	padding: 30px 45px;
	border-top: solid 10px #ebebeb;
	
	@include media-breakpoint-down(xs) {
		padding: 15px;
	}
}
</style>