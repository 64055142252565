class Api
{
	ServerAPI: any = null
	$axios: any = {}
	csrf_token: string = ''

	async route(params)
	{
		return this.runApiMethod('route', params)
	}

	async getCollectionProducts(params)
	{
		return this.runApiMethod('getCollectionProducts', params)
	}

	async getProduct(params)
	{
		return this.runApiMethod('getProduct', params)
	}

	async addToCart(params)
	{
		return this.runApiMethod('addToCart', params)
	}

	async getServerUpdates(params)
	{
		return this.runApiMethod('getServerUpdates', params)
	}

	async getCartProducts(params)
	{
		return this.runApiMethod('getCartProducts', params)
	}

	async setCartProductQuantity(params)
	{
		return this.runApiMethod('setCartProductQuantity', params)
	}

	async deleteProductFromCart(params)
	{
		return this.runApiMethod('deleteProductFromCart', params)
	}

	async addToList(params)
	{
		return this.runApiMethod('addToList', params)
	}

	async removeFromList(params)
	{
		return this.runApiMethod('removeFromList', params)
	}

	async getUpsellProductsByCart(params)
	{
		return this.runApiMethod('getUpsellProductsByCart', params)
	}
	
	async calculateTax(params)
	{
		return this.runApiMethod('calculateTax', params)
	}
	
	async calculateCart(params)
	{
		return this.runApiMethod('calculateCart', params)
	}
	
	async saveCheckoutInfo(params)
	{
		return this.runApiMethod('saveCheckoutInfo', params)
	}
	
	async createOrderFromPaymentIntent(params)
	{
		return this.runApiMethod('createOrderFromPaymentIntent', params)
	}
	
	async saveForLater(params)
	{
		return this.runApiMethod('saveForLater', params)
	}
	
	async moveToCart(params)
	{
		return this.runApiMethod('moveToCart', params)
	}
	
	async getListsProducts(params)
	{
		return this.runApiMethod('getListsProducts', params)
	}
	
	async search(params)
	{
		return this.runApiMethod('search', params)
	}
	
	async countCollectionsProducts(params)
	{
		return this.runApiMethod('countCollectionsProducts', params)
	}
	
	async getCustomerCity(params)
	{
		return this.runApiMethod('getCustomerCity', params)
	}
	
	async getPopularProducts(params)
	{
		return this.runApiMethod('getPopularProducts', params)
	}
	
	async getSubcategoriesProducts(params)
	{
		return this.runApiMethod('getSubcategoriesProducts', params)
	}
	
	async saveCustomerZip(params)
	{
		return this.runApiMethod('saveCustomerZip', params)
	}
	
	async submitProductQuestion(params)
	{
		return this.runApiMethod('submitProductQuestion', params)
	}
	
	async checkIfCustomerIsRegistered(params)
	{
		return this.runApiMethod('checkIfCustomerIsRegistered', params)
	}
	
	async registerCustomer(params)
	{
		return this.runApiMethod('registerCustomer', params)
	}
	
	async loginCustomer(params)
	{
		return this.runApiMethod('loginCustomer', params)
	}
	
	async getCartData(params)
	{
		return this.runApiMethod('getCartData', params)
	}

	async mergeCartByCode(params)
	{
		return this.runApiMethod('mergeCartByCode', params)
	}
	
	// --------------------------------------------------------------------------------------------------------------

	async runApiMethod(method_name, params)
	{
		if ((process as any).server)
		{
			// const ServerAPI = require('../api/server').default
			const ServerAPI = this.ServerAPI ? this.ServerAPI : this.ServerAPI = require('../api/server').default
			const method = ServerAPI[method_name]
			if (!method)
			{
				console.error('API method ' + method_name + ' not found')
				return
			}
			return method.call(ServerAPI, params)
		}
		else
		{
			if (!this || !this.$axios)
			{
				console.error('Axios module not found in API plugin')
				return false
			}
			
			const result = this.$axios.$post('/api/v1/' + method_name, params, {
				headers: {
					'X-Csrf-Token': this.csrf_token
				}
			}).catch(function(error) {
				if (error.response && error.response.data.status == 'error' && error.response.data.error == 'csrf')
					window.location.reload()
				else
					console.error('API error', error)
			})
			
			return result
		}
	}

	setCustomer(customer: object)
	{
		if ((process as any).server)
		{
			const ServerAPI = this.ServerAPI ? this.ServerAPI : this.ServerAPI = require('../api/server').default
			ServerAPI.customer = customer
		}
	}
}

export default (context, inject) => {
	
	if (!context.store.state.customer)
		throw Error('failed to connect API module: no customer data in store')

	context.$api = new Api()
	context.$api.$axios = context.$axios
	context.$api.csrf_token = context.store.state.customer.csrf_token
	inject('api', context.$api)
}