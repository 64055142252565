<template>
<div v-show="can_make_payment">
	<!-- <button class="hidden-button" ref="hiddenButton" v-if="type == 'hidden'">&nbsp;</button> -->
	<!-- <button class="apple-pay-button" ref="apple_pay_button" v-if="type == 'apple-pay'" @click="this.showPaymentUI">Buy with <font-awesome-icon :icon="['fab', 'apple']" /><strong>Pay</strong></button> -->
	<!-- <button class="payment-request-button" ref="payment_request_button" v-if="type == 'payment-request'" @click="this.showPaymentUI">Buy now</button> -->
	<div ref="payment_request_button" v-show="type == 'payment-request'" class="payment-request-button"></div>
	
	<div v-if="type == 'apple-pay'" :class="[ 'apple-pay-button', { 'in-cart': placement == 'cart', 'in-checkout': placement == 'checkout' } ]" ref="apple_pay_button" @click="this.showPaymentUI">
		<div :class="[ 'button', 'apple-pay-button-with-text', { 'apple-pay-button-white-with-text': placement != 'checkout', 'apple-pay-button-black-with-text': placement == 'checkout' } ]">
			<template v-if="!button_loading_indicator">
				<span class="text" v-if="placement == 'product'">Buy with</span>
				<span class="text" v-if="placement == 'checkout'">Check out with</span>
				<span class="logo"></span>
			</template>
			<div v-else class="loading-indicator">&nbsp;</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Stripe from '~/mixins/Stripe'
import TrackOrder from '~/mixins/TrackOrder'

export default
{
	mixins: [ Stripe, TrackOrder ],
	
	props: [
		'product',
		'placement',
	],

	data: () => ({
		cart: [],
		can_make_payment: false,
		type: 'hidden',
		payment_request: {},
		payment_intent: {
			id: null,
			client_secret: null,
		},
		button_loading_indicator: false,
	}),
	
	computed: {
		...mapState({
			// cart_products: state => state.cart_products,
			customer: state => state.customer,
		}),
		
		subtotal: function()
		{
			return this.cart.reduce((sum, item) => {
				const c = item.quantity * item.cart_price
				return sum + c
			}, 0)
		},
	},

	methods: {
		...mapActions([
			'setSubmittedOrderNumber',
			'setSubmittedOrder',
		]),
		
		showPaymentUI()
		{
			// TODO: show loading indication, turn it back off after opening apple pay dialog
			this.button_loading_indicator = true
			this.payment_request.show()
			setTimeout(() => {
				this.button_loading_indicator = false
			}, 5000)
			
			this.$track.event({
				gtm: {
					category: 'Checkout',
					action: 'Express Checkout Button click',
					label: 'Apple Pay',
				},
				fb: {
					event: 'ExpressCheckoutButtonClick',
				},
			})
		},
		
		async setup()
		{
			if (!this.stripe)
				await this.initStripe()
			
			const paymentRequest = this.stripe.paymentRequest({
				country: 'US',
				currency: 'usd',
				// displayItems: { // use this for cart payment mode
				// 	label: this.product.title,
				// 	amount: this.product.list_price*100,
				// }],
				total: {
					label: this.type == 'apple-pay' ? 'Homekilo' : 'Total',
					amount: Math.round(this.subtotal * 100),
					pending: true,
				},
				requestPayerName: true,
				requestPayerEmail: true,
				requestPayerPhone: true,
				requestShipping: true,
			})
			
			this.payment_request = paymentRequest
			
			paymentRequest.on('shippingaddresschange', this.shippingAddressChanged)
			paymentRequest.on('paymentmethod', this.paymentMethodSelected)
			paymentRequest.on('cancel', this.cancelled)
		
			// Check the availability of the Payment Request API first.
			this.can_make_payment = await paymentRequest.canMakePayment()
			if (!this.can_make_payment)
				return false
			
			if (this.can_make_payment.applePay)
			{
				this.type = 'apple-pay'
			}
			else if (this.placement == 'checkout')
			{
				return
			}
			else if (this.$refs.payment_request_button)
			{
				const elements = this.stripe.elements()
				const prButton = elements.create('paymentRequestButton', {
					paymentRequest,
					style: {
						paymentRequestButton: {
							type: this.placement == 'product' ? 'buy' : 'default',
							theme: 'light-outline',
							// height: '40px',
						},
					},
				})
				prButton.mount(this.$refs.payment_request_button)
				this.type = 'payment-request'
			}
			
			// if (result)
				// prButton.mount(this.$refs.payment_request_button);

			// (async () => {
			// 	// Check the availability of the Payment Request API first.
			// 	const result = await paymentRequest.canMakePayment();
			// 	console.log(this.$refs.payment_request_button)
			// 	if (result)
			// 		prButton.mount(this.$refs.payment_request_button);
			// 	// else
			// 	// 	console.log('canMakePayment() false')
			// })()
		},
		
		async shippingAddressChanged(event)
		{
			if (event.shippingAddress.country.toUpperCase() !== 'US') // TODO: add international shipping & tax calculation, remove this limitation
				return event.updateWith({ status: 'invalid_shipping_address' })
			
			// https://stripe.com/docs/js/appendix/shipping_address
			const result = await this.$api.calculateCart({
				cart: this.cart,
				full_name: event.shippingAddress.recipient,
				shipping_address: {
					street_address: event.shippingAddress.addressLine,
					unit_number: '',
					city: event.shippingAddress.city,
					state: event.shippingAddress.region,
					zip: event.shippingAddress.postalCode,
					country: event.shippingAddress.country,
					phone: event.shippingAddress.phone,
				},
			})
			
			if (!result || result.status == 'error')
				return event.updateWith({ status: 'fail' })
			
			if (result && result.payment_intent)
				this.payment_intent = result.payment_intent
			
			// let items = result.items.map((item) => ({
			// 	amount: item.list_price * item.quantity * 100,
			// 	label: item.title,
			// }))
			let items = [
				{
					amount: result.subtotal * 100,
					label: 'Subtotal',
				}
			]
			
			if (result && result.tax)
				items.push({ amount: Math.round(result.tax * 100), label: 'Sales tax' })
			
			event.updateWith({
				status: 'success',
				displayItems: items.length > 1 ? items : [],
				total: {
					amount: Math.round(result.total * 100),
					label: this.type == 'apple-pay' ? 'Homekilo' : 'Total',
					pending: false,
				},
				shippingOptions: result.shipping_options,
			})
		},
		
		async paymentMethodSelected(event)
		{
			// console.log('paymentMethodSelected(event)', event)
			const { paymentIntent: payment_intent, error } = await this.stripe.confirmCardPayment(this.payment_intent.client_secret, {
				payment_method: event.paymentMethod.id,
				shipping: {
					address: {
						line1: event.shippingAddress.addressLine.join(', '),
						line2: '',
						city: event.shippingAddress.city,
						state: event.shippingAddress.region,
						postal_code: event.shippingAddress.postalCode,
						country: event.shippingAddress.country,
					},
					name: event.shippingAddress.recipient,
					phone: event.shippingAddress.phone,
				},
			}, {
				handleActions: false
			})
			
			if (error)
				return event.complete('fail')
			
			// Close the browser payment UI successfully
			event.complete('success')
			
			this.paymentSuccessful(payment_intent)
			
			// Looks like this was needed for automatic redirects that we are not using, commenting out for now
			/*
			const { paymentIntent: payment_intent, error } = await this.stripe.confirmCardPayment(this.payment_intent.client_secret)
			if (error)
			{
				console.error('Error finalizing payment')
				alert('There was a problem processing your payment. Please try again or use a different card.')
				// TODO: track this as event
			}
			else
			{
				this.paymendSuccessful(payment_intent)
			}
			*/
		},
		
		cancelled(event)
		{
			this.$track.event({
				gtm: {
					category: 'Checkout',
					action: 'Express Checkout Cancelled',
				},
				fb: {
					event: 'ExpressCheckoutCancelled',
				},
			})
		},
		
		async paymentSuccessful(payment_intent)
		{
			// Payment was successful, create an order, show confirmation
			// payment_intent has all customer info
			// TODO: create order with items, add order id to checkout (mongo), reset checkout in customer (redis), track everything
			// console.log('payment successful', payment_intent)
			
			try
			{
				const result = await this.$api.createOrderFromPaymentIntent({
					cart: this.cart,
					payment_intent: payment_intent,
				})
			
				if (result && result.status == 'ok')
				{
					this.setSubmittedOrderNumber(result.order.number) // TODO: remove after changing references to order number
					this.setSubmittedOrder(result.order)
					
					this.$router.push({
						path: '/orders/',
					})
					
					// Reset cart, etc
					const r = await this.$api.getServerUpdates()
					if (r && r.status == 'ok')
					{
						this.$store.dispatch('applyServerUpdates', r)
					}
					
					this.trackOrder(result.order)
				}
				else
				{
					throw result.error ? result.error : { error: true }
				}
			}
			catch (error)
			{
				console.error(error)
				alert('Looks like there was a problem processing your order. No need to worry: our customer service will contact you as soon as possible for order confirmation.')
			}
		},
	},

	mounted() {
		if (this.product)
			this.cart = [ { sku: this.product.sku, quantity: 1, params: {}, list_price: this.product.list_price, cart_price: this.product.discounted_price } ]
		else
			this.cart = this.customer.cart
		
		if (!this.cart.length)
			return
		
		if (process.client && process.env.NODE_ENV == 'production')
			this.setup()
	},
}
</script>
<style lang="scss" scoped>
.payment-request-button {
	margin: 5px 0;
}

// .hidden-button,
.apple-pay-button {
	margin: 5px 0;
	color: #000;
	background: #fff;
	border: solid 1px #000;
	// border-width: 0.5px;
	// border-radius: 5px;
	font-size: 18px;
	font-weight: 400;
	white-space: nowrap;
	padding: 6px 2px;
	cursor: pointer;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}
	
	&:hover {
		filter: brightness(95%);
	}
	
	&.in-cart {
		border: solid 1px $border-color-2;
		border-radius: 3px;
		display: flex;
		margin: 5px 0 10px 0;
		
		@include media-breakpoint-down(xs) {
			margin: 0;
			padding-top: 7px;
			padding-bottom: 7px;
		}
	}
	
	&.in-checkout {
		// border: solid 1px $border-color-2;
		// border-radius: 3px;
		// display: flex;
		// margin: 5px 0 10px 0;
		color: #fff;
		background: #000;
		padding: 2px;
		
		&:hover {
			filter: none;
			background: #333;
			border-color: #333;
			
			.apple-pay-button-black-with-text {
				background: #333;
			}
			
			.logo {
				background-color: #333;
			}
		}
		
		.button {
			font-size: 16px;
			
			.logo {
				width: 45px;
			}
		}
	}

	.button {
		width: 100%;
		height: 35px;
		font-size: 18px;
		// position: relative;
		// margin-bottom: -7px; // fixing apple's margin-bottom for the button
		
		@include media-breakpoint-down(lg) {
			// height: 30px;
			// font-size: 16px;
		}
		
		.text {
			font-weight: normal;
		}
		
		.logo {
			width: 50px;
			position: relative;
			bottom: -1px;
			
			@include media-breakpoint-down(lg) {
				// width: 40px;
			}
		}
		
		@keyframes spinner {
			to {transform: rotate(360deg);}
		}
		
		.loading-indicator {
			position: relative;
			
			&:before {
				content: '';
				box-sizing: border-box;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 16px;
				height: 16px;
				margin-top: -8px;
				margin-left: -8px;
				border-radius: 50%;
				border: 2px solid #ccc;
				border-top-color: #000;
				animation: spinner .9s linear infinite;
				
				@include media-breakpoint-down(lg) {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
}

.hidden-button {
	visibility: hidden;
}


.apple-pay-button-with-text {
	--apple-pay-scale: 1; /* (height / 32) */
	display: inline-flex;
	justify-content: center;
	font-size: 12px;
	border-radius: 5px;
	padding: 0px;
	box-sizing: border-box;
	// min-width: 200px;
	// min-height: 32px;
	max-height: 64px;
}
.apple-pay-button-black-with-text {
	background-color: black;
	color: white;
}
.apple-pay-button-white-with-text {
	background-color: white;
	color: black;
}
.apple-pay-button-white-with-line-with-text {
	background-color: white;
	color: black;
	border: .5px solid black;
}
.apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
	background-image: -webkit-named-image(apple-pay-logo-white);
	background-color: black;
}
.apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
	background-image: -webkit-named-image(apple-pay-logo-black);
	background-color: white;
}
.apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
	background-image: -webkit-named-image(apple-pay-logo-black);
	background-color: white;
}
.apple-pay-button-with-text > .text {
	font-family: -apple-system;
	font-size: calc(1em * var(--apple-pay-scale));
	font-weight: 300;
	align-self: center;
	margin-right: calc(2px * var(--apple-pay-scale));
}
.apple-pay-button-with-text > .logo {
	width: calc(35px * var(--scale));
	height: 100%;
	background-size: 100% 60%;
	background-repeat: no-repeat;
	background-position: 0 50%;
	margin-left: calc(2px * var(--apple-pay-scale));
	border: none;
}
</style>