<template>
<div class="logo">
	<nuxt-link v-if="!is_index" to="/">homekilo</nuxt-link>
	<span v-else>homekilo</span>
</div>	
</template>

<script>
export default {
	computed: {
		is_index()
		{
			return this.$nuxt.$route.name == 'index'
		},
		
		is_checkout()
		{
			return this.$nuxt.$route.name == 'checkout'
		},
	},
}
</script>

<style lang="scss" scoped>
.logo {
	font-size: 28px;
	font-weight: 900;

	@include media-breakpoint-down(sm) {
		font-size: 18px;
	}

	span {
		cursor: default;
	}
	
	a {
		color: #000;
		text-decoration: none;
		
		&:hover {
			color: $hover-color;
		}
	}
}
</style>