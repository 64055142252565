<template>
	<nuxt-link :to="url_prefix + product.full_url" :class="[ 'product-item', { 'product-out-of-stock': !this.product.available } ]" v-on:click.native="openProduct">
		<span class="preview">
			<span class="img" :style="{
				'background-image': product.images && product.images[0] ? 'url(/images/products/' + product.sku + '/' + product.images[0] + '/720.jpg)' : 'none',
			}"></span>
		</span>
			<!-- <img v-if="product.images[0]" :src="'/images/products/' + product.sku + '/' + product.images[0] + '/360.jpg'" src-placeholder="data:image/gif;base64,R0lGODlhAQABAPAAAAAAACZFySH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" :key="product.images[0]" :alt="product.title" :srcset="generateImageSrcSet('/images/products/' + product.sku + '/' + product.images[0])" sizes="(min-width: 768px) 250px, (min-width: 1200px) 400px, 50vw" /></span> -->
		<span class="pricing">
			<Currency :price="product.discounted_price" currency="USD" class="list-price" />
			<Currency :price="product.original_price" currency="USD" class="original-price" v-if="product.original_price && product.original_price > product.discounted_price" />
			<span class="discount-comment" v-if="product.original_price && product.original_price > product.discounted_price">
				{{ Math.round( (1 - product.discounted_price / product.original_price) * 100 ) }}%&nbsp;off
				<span v-if="discount_expires" v-html="'until&nbsp;' + discount_expires"></span>
			</span>
		</span>
		<span v-html="product.title"></span>
		<div class="item-info">
			<span class="low-inventory" v-if="this.product.available && this.product.available <= 5">Only {{ this.product.available }} left</span>
			<span class="in-cart" v-if="in_cart"><font-awesome-icon :icon="['far', 'check']" /> Added to cart</span>
			<span class="out-of-stock" v-if="!this.product.available">Out of stock</span>
		</div>
	</nuxt-link>
</template>

<script>
import {
	find as _find,
	matches as _matches
} from 'lodash'

import { mapState, mapActions } from 'vuex'

import Currency from '~/components/common/Currency'

export default {
	components: {
		Currency,
	},

	props: {
		product: Object,
		url_prefix: { type: String, default: '' },
	},

	methods: {
		...mapActions([
			'setCurrentProduct'
		]),

		openProduct(event)
		{
			if (!event.metaKey)
				this.setCurrentProduct(this.product)
		},

		generateImageSrcSet(image_dir)
		{
			const sizes = [360, 400, 720, 800, 846]
			let result = []
			for (const size of sizes)
			{
				result.push(image_dir + '/' + size + '.jpg ' + size + 'w')
			}
			return result.join(', ')
		},
	},

	computed: {
		...mapState({
			cart: state => state.customer.cart,
		}),

		in_cart: function()
		{
			return _find(this.cart, _matches({ sku: this.product.sku }))
		},
		
		discount_expires: function()
		{
			if (!this.product.discount_expires)
				return false
			
			const date = new Date(this.product.discount_expires)
			if (Date.now() > date)
				return false
			
			return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][date.getMonth()]
				+ '&nbsp;'
				+ date.getDate()
		},
	},
}
</script>

<style lang="scss" scoped>
// .v-lazy-image {
// 	opacity: 0;
// 	transition: opacity 2s;
// }
// .v-lazy-image-loaded {
// 	opacity: 1;
// }

.product-item {
	display: block;
	margin-bottom: 5rem;

	text-decoration: none;
	color: #000;
	font-size: 16px;
	line-height: 1.2;

	@include media-breakpoint-down(lg) {
		margin-bottom: 3rem;
		font-size: 14px;
	}

	&:hover {
		color: #000;
		text-decoration: none;
		transition: none;

		img,
		.img {
			filter: brightness(95%);
			transition: none;
			
			@media (hover: none) and (pointer: coarse) {
				filter: none;
			}
		}
		
		.preview {
			background: rgba(0, 0, 0, 0.05);
			transition: none;
			
			@media (hover: none) and (pointer: coarse) {
				background: none;
			}
		}
	}
	
	.pricing {
		color: #000;
		font-size: 18px;
		
		.list-price {
			font-size: 18px;
			font-weight: 900;

			@include media-breakpoint-down(lg) {
				font-size: 16px;
			}

			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}

		.original-price {
			font-size: 18px;
			font-weight: 300;
			margin-left: 5px;
			margin-right: 5px;
			@include strikeout;

			@include media-breakpoint-down(lg) {
				font-size: 16px;
			}
			
			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}
		
		.discount-comment {
			font-size: 14px;
			font-style: italic;
			color: #d60;
			white-space: nowrap;
			
			@include media-breakpoint-down(lg) {
				// font-size: 16px;
			}
			
			@include media-breakpoint-down(md) {
				font-size: 13px;
			}
		}
	}

	.preview {
		display: flex;
		flex-basis: auto;
		transition: ease background 0.3s;
	}
	
	.img {
		display: flex;
		
		&:before {
			content: '';
			padding-top: 100%;
		}
	}

	img,
	.img {
		align-self: center;
		width: 100%;
		transition: ease filter 0.3s;
		
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
	
	&.product-out-of-stock {
		img {
			opacity: 0.5;
		}
		
		&:hover img {
			opacity: 1;
		}
	}

	.pricing {
		display: block;
		margin: 10px 0 5px 0;
		
		.list-price {
			font-weight: 800;
		}
	}

	.item-info {
		font-size: 12px;
		margin-top: 5px;

		span {
			white-space: nowrap;
			margin-right: 0.5rem;

			&.low-inventory {
				color: #d60;
			}

			&.in-cart {
				color: #1c7b00;
			}

			&.out-of-stock {
				color: #800;
			}
		}
	}
}
</style>