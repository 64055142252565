<template>
<span class="header-link" @mouseover="mouseover" @mouseout="mouseout" @click="click" aria-label="Register or Sign in">
	<font-awesome-icon :icon="['fas', 'user']" class="d-none d-sm-inline-block" />
	<font-awesome-icon :icon="['far', 'user']" class="d-sm-none" />
	<span class="d-none d-xxl-inline" v-html="customer.is_registered ? 'Account' : 'Register or Sign&nbsp;in'"></span>
	<span class="d-none d-sm-inline d-xxl-none">Account</span>
	<transition name="fade"><SignupOfferNotification v-show="showSignupOfferNotification()" /></transition>
</span>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import SignupOfferNotification from '~/components/signup/SignupOfferNotification'

export default
{
	components: {
		SignupOfferNotification,
	},
	
	computed: {
		...mapState({
			customer: state => state.customer,
			show_registration_form: state => state.show_registration_form,
			show_signup_offer_notification: state => state.show_signup_offer_notification,
		}),
	},

	methods: {
		...mapActions([
			'showRegistrationForm',
		]),
		
		click()
		{
			if (this.customer.is_registered)
			{
				// TODO: show account
				
				
				
				this.$track.event({
					gtm: {
						category: 'Account',
						action: 'Account link clicked in header',
					},
					fb: {
						event: 'AccountLinkClickedInHeader',
					}
				})
			}
			else
			{
				this.showRegistrationForm()
				
				this.$track.event({
					gtm: {
						category: 'Account',
						action: 'Signup link clicked in header',
					},
					fb: {
						event: 'AccountSignupLinkClickedInHeader',
					}
				})
			}
		},

		showSignupOfferNotification()
		{
			const not_on_pages = [
				'orders',
				'checkout',
			]

			// TODO: also hide if customer just made a purchase and is browsing the website again

			return (
					this.show_signup_offer_notification
				&&	!this.show_registration_form
				&&	!this.customer.is_registered
				&&	!not_on_pages.includes(this.$nuxt.$route.name)
			)
		},
		
		mouseover()
		{
			// this.clearTimeoutToHideSignupOfferNotification()
		},
		
		mouseout()
		{
			// this.resetTimeoutToHideSignupOfferNotification()
		},
	},
	
	mounted()
	{
		
	},
}
</script>
<style lang="scss" scoped>

</style>