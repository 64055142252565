<template>
<div class="body-spacing page-header">
	<div class="header">
		<div class="container-fluid">
			<div class="row">
				<div class="d-none d-sm-block col-12 col-sm-2 col-md-3 col-xl-2 logo-container">
					<Logo />
				</div>
				<div class="d-none d-sm-block col-12 col-sm col-lg">
					<SearchBar />
				</div>
				<div class="d-none d-sm-block col-sm-6 col-md-5 col-lg-4">
					<AccountControls />
				</div>
			</div>
		</div>
	</div>
	
	<MainMenu />
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Logo from '~/components/common/Logo'
import SearchBar from '~/components/search/SearchBar'
import AccountControls from '~/components/common/AccountControls'
import MainMenu from '~/components/navigation/MainMenu'

export default
{
	components: {
		Logo,
		SearchBar,
		AccountControls,
		MainMenu,
	},
	
	methods: {
		...mapActions([
			'showAllCategoriesMenu',
		]),
	},
}
</script>


<style lang="scss" scoped>
.page-header {
	padding-bottom: 4px;
	z-index: 10;
	
	@include media-breakpoint-down(xs) {
		background: #fff;
		padding: 0;
		// z-index: 11;
		// padding: 10px 0;
	}
}

.modal-page-header {
	z-index: 10;
	background: #fff;
	// padding: 1.2rem 1.6rem 4px 1.6rem;
	padding-top: 1.2rem;
	// padding-bottom: 4px;

	@include media-breakpoint-down(lg) {
		// padding: 0.8rem 0 4px 0;
		padding-top: 0.8rem;
	}
	
	@include media-breakpoint-down(sm) {
		padding-top: 0.5rem;
	}

	@include media-breakpoint-down(xs) {
		display: none;
	}
}

.logo-container {
	// @include media-breakpoint-up(xl) {
	// 	@include sidebar; // breaks columns under lg
	// }
	@include sidebar;
}
</style>