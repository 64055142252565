// A category can have children categories OR an attached collection with products

const categories_data = [

	{
		url: 'furniture',
		title: 'Furniture',
		menu_title: 'Furniture',
		html_title: 'Furniture',
		collection: null,
		template: 'subcategories',
		subcategories: [
		
			{
				url: 'furniture-by-room',
				title: 'Furniture by&nbsp;room',
				menu_title: 'By&nbsp;room',
				html_title: 'Furniture by room',
				collection: null,
				template: 'subcategories',
				skip_to_subcategories: true,
				subcategories: [

					{
						url: 'living-room-furniture',
						title: 'Living room furniture',
						menu_title: 'Living room',
						html_title: 'Living room furniture',
						collection: '5e051c9126c2b131bf464ca2',
						template: 'collection',
						description: '',
						subcategories: null
					},

					{
						url: 'bedroom-furniture',
						title: 'Bedroom furniture',
						menu_title: 'Bedroom',
						html_title: 'Bedroom furniture',
						collection: '5ebdd0549db0c4682f3881c6',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'furniture-bathroom',
						title: 'Bathroom furniture',
						menu_title: 'Bathroom',
						html_title: 'Bathroom furniture',
						collection: '5ebdd0f29db0c4682f3881c9',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'furniture-kitchen',
						title: 'Kitchen furniture',
						menu_title: 'Kitchen',
						html_title: 'Kitchen furniture',
						collection: '5ebdd6ff9db0c4682f3881db',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'dining-furniture',
						title: 'Dining room furniture',
						menu_title: 'Dining',
						html_title: 'Dining room furniture',
						collection: '5ebdd2099db0c4682f3881cf',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'bar-furniture',
						title: 'Bar furniture',
						menu_title: 'Bar',
						html_title: 'Bar furniture',
						collection: '5ebde09b9db0c4682f3881f8',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'entryway-furniture',
						title: 'Entryway furniture',
						menu_title: 'Entryway',
						html_title: 'Entryway furniture',
						collection: '5ec4683873013e394a07be52',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'office-furniture',
						title: 'Office furniture',
						menu_title: 'Office',
						html_title: 'Office furniture',
						collection: '5ebdd12d9db0c4682f3881cb',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'kids-bedroom-furniture',
						title: 'Kids bedroom furniture',
						menu_title: 'Kids bedroom',
						html_title: 'Kids bedroom furniture',
						collection: '5ebe00039db0c4682f388252',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-outdoor',
						title: 'Outdoor furniture',
						menu_title: 'Outdoor',
						html_title: 'Outdoor furniture',
						collection: '5ebdcf7b9db0c4682f3881c5',
						template: 'collection',
						subcategories: null
					},

				]
			},

			{
				url: 'furniture-by-type',
				title: 'Furniture by&nbsp;type',
				menu_title: 'By&nbsp;type',
				html_title: 'Furniture by type',
				collection: null,
				template: 'subcategories',
				subcategories: [

					{
						url: 'accent-chairs',
						title: 'Accent chairs',
						menu_title: 'Accent chairs',
						html_title: 'Accent chairs',
						collection: '5ebdee4d9db0c4682f388219',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'accent-tables',
						title: 'Accent tables',
						menu_title: 'Accent tables',
						html_title: 'Accent tables',
						collection: '5ebe04269db0c4682f388254',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'armchairs',
						title: 'Armchairs',
						menu_title: 'Armchairs',
						html_title: 'Armchairs',
						collection: '5ebe00c39db0c4682f388253',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'barstools',
						title: 'Bar stools',
						menu_title: 'Bar stools',
						html_title: 'Bar stools',
						collection: '5ebde0af9db0c4682f3881f9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bathroom-cabinets',
						title: 'Bathroom cabinets',
						menu_title: 'Bathroom cabinets',
						html_title: 'Bathroom cabinets',
						collection: '5ebdd1079db0c4682f3881ca',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-bathroom-mirrors',
						title: 'Bathroom mirrors',
						menu_title: 'Bathroom mirrors',
						html_title: 'Bathroom mirrors',
						collection: '5ebdde499db0c4682f3881ef',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-bathroom-vanities',
						title: 'Bathroom vanities',
						menu_title: 'Bathroom vanities',
						html_title: 'Bathroom vanities',
						collection: '5ebdf4539db0c4682f388234',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bean-bag-chairs',
						title: 'Bean bag chairs',
						menu_title: 'Bean bag chairs',
						html_title: 'Bean bag chairs',
						collection: '5ebdfae29db0c4682f388251',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'beds',
						title: 'Beds &amp; bed frames',
						menu_title: 'Beds &amp; bed frames',
						html_title: 'Beds & bed frames',
						collection: '5ebdda6a9db0c4682f3881e3',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'benches',
						title: 'Benches',
						menu_title: 'Benches',
						html_title: 'Benches',
						collection: '5ebdd6d29db0c4682f3881da',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bookcases',
						title: 'Bookcases',
						menu_title: 'Bookcases',
						html_title: 'Bookcases',
						collection: '5ebdceee9db0c4682f3881c3',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'buffets',
						title: 'Sideboards &amp; buffets',
						menu_title: 'Buffets',
						html_title: 'Sideboards & buffets',
						collection: '5ebdd28a9db0c4682f3881d0',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'cabinets',
						title: 'Storage cabinets',
						menu_title: 'Cabinets',
						html_title: 'Storage cabinets',
						collection: '5ebdf1779db0c4682f388229',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'chairs',
						title: 'Chairs',
						menu_title: 'Chairs',
						html_title: 'Chairs',
						collection: '5edabf07beaccb24df27bc7b',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'chaise-lounge-chairs',
						title: 'Chaise lounge chairs',
						menu_title: 'Chaise lounge chairs',
						html_title: 'Chaise lounge chairs',
						collection: '5ebdf7fe9db0c4682f388245',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'chests',
						title: 'Chests',
						menu_title: 'Chests',
						html_title: 'Chests',
						collection: '5edad07cbeaccb24df27bc96',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'coat-racks',
						title: 'Coat racks',
						menu_title: 'Coat racks',
						html_title: 'Coat racks',
						collection: '5ebdd32c9db0c4682f3881d3',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'coffee-tables',
						title: 'Coffee tables',
						menu_title: 'Coffee tables',
						html_title: 'Coffee tables',
						collection: '5ebdecde9db0c4682f388216',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'computer-desks',
						title: 'Computer desks',
						menu_title: 'Computer desks',
						html_title: 'Computer desks',
						collection: '5ebdd1379db0c4682f3881cd',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'console-tables',
						title: 'Console tables',
						menu_title: 'Console tables',
						html_title: 'Console tables',
						collection: '5ebde0549db0c4682f3881f7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'couches',
						title: 'Couches',
						menu_title: 'Couches',
						html_title: 'Couches',
						collection: '5edacea1beaccb24df27bc92',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'cushions',
						title: 'Seat cushions',
						menu_title: 'Cushions',
						html_title: 'Seat cushions',
						collection: '5edac65abeaccb24df27bc84',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'dining-chairs',
						title: 'Dining chairs',
						menu_title: 'Dining chairs',
						html_title: 'Dining chairs',
						collection: '5ebde64f9db0c4682f38820d',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'dining-sets',
						title: 'Dining sets',
						menu_title: 'Dining sets',
						html_title: 'Dining sets',
						collection: '5ebde6749db0c4682f38820e',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'dining-tables',
						title: 'Dining tables',
						menu_title: 'Dining tables',
						html_title: 'Dining tables',
						collection: '5ebdf2969db0c4682f388230',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'dressers',
						title: 'Nightstands &amp; Dressers',
						menu_title: 'Dressers',
						html_title: 'Nightstands & Dressers',
						collection: '5ebdd4949db0c4682f3881d9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-filing-cabinets',
						title: 'Filing cabinets',
						menu_title: 'Filing cabinets',
						html_title: 'Filing cabinets',
						collection: '5ebdf0e79db0c4682f388225',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'fireplaces',
						title: 'Fireplaces',
						menu_title: 'Fireplaces',
						html_title: 'Fireplaces',
						collection: '5ebdd4179db0c4682f3881d7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'folding-tables',
						title: 'Folding tables',
						menu_title: 'Folding tables',
						html_title: 'Folding tables',
						collection: '5ebde3ea9db0c4682f388202',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'futons',
						title: 'Futons',
						menu_title: 'Futons',
						html_title: 'Futons',
						collection: '5ebde1399db0c4682f3881fb',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'hammocks',
						title: 'Hammocks',
						menu_title: 'Hammocks',
						html_title: 'Hammocks',
						collection: '5ebdeca89db0c4682f388215',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'headboards',
						title: 'Headboards',
						menu_title: 'Headboards',
						html_title: 'Headboards',
						collection: '5ebde0259db0c4682f3881f6',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-kids-dressers',
						title: 'Kids dressers',
						menu_title: 'Kids dressers',
						html_title: 'Kids dressers',
						collection: '5ebe00039db0c4682f388252',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-kitchen-cabinets',
						title: 'Kitchen cabinets',
						menu_title: 'Kitchen cabinets',
						html_title: 'Kitchen cabinets',
						collection: '5edac797beaccb24df27bc86',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-kitchen-carts',
						title: 'Kitchen carts',
						menu_title: 'Kitchen carts',
						html_title: 'Kitchen carts',
						collection: '5ebdd7199db0c4682f3881dc',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'living-room-sets',
						title: 'Living room sets',
						menu_title: 'Living room sets',
						html_title: 'Living room sets',
						collection: '5edabc6bbeaccb24df27bc78',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'loveseats',
						title: 'Loveseats',
						menu_title: 'Loveseats',
						html_title: 'Loveseats',
						collection: '5edabcefbeaccb24df27bc79',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'mirrors',
						title: 'Mirrors',
						menu_title: 'Mirrors',
						html_title: 'Mirrors',
						collection: '5ebdd45f9db0c4682f3881d8',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'nightstands',
						title: 'Nightstands &amp; Dressers',
						menu_title: 'Nightstands',
						html_title: 'Nightstands & Dressers',
						collection: '5ebdd4949db0c4682f3881d9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'office-desks',
						title: 'Office desks',
						menu_title: 'Office desks',
						html_title: 'Office desks',
						collection: '5ebdd1319db0c4682f3881cc',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'office-chairs',
						title: 'Office chairs',
						menu_title: 'Office chairs',
						html_title: 'Office chairs',
						collection: '5ebdd18b9db0c4682f3881ce',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'ottomans',
						title: 'Ottomans',
						menu_title: 'Ottomans',
						html_title: 'Ottomans',
						collection: '5edac07abeaccb24df27bc7c',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'patio-chairs',
						title: 'Patio chairs',
						menu_title: 'Patio chairs',
						html_title: 'Patio chairs',
						collection: '5ebdcf479db0c4682f3881c4',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'patio-tables',
						title: 'Patio tables',
						menu_title: 'Patio tables',
						html_title: 'Patio tables',
						collection: '5ebdec889db0c4682f388214',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-patio-sets',
						title: 'Patio furniture sets',
						menu_title: 'Patio sets',
						html_title: 'Patio furniture sets',
						collection: '5ebddb639db0c4682f3881e7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'poufs',
						title: 'Poufs &amp; bean bags',
						menu_title: 'Poufs',
						html_title: 'Poufs & bean bags',
						collection: '5edad194beaccb24df27bc97',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-printer-stands',
						title: 'Printer stands',
						menu_title: 'Printer stands',
						html_title: 'Printer stands',
						collection: '5ebdf0729db0c4682f388223',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'recliners',
						title: 'Recliners',
						menu_title: 'Recliners',
						html_title: 'Recliners',
						collection: '5ebdde679db0c4682f3881f0',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'rocking-chairs',
						title: 'Rocking chairs',
						menu_title: 'Rocking chairs',
						html_title: 'Rocking chairs',
						collection: '5edad031beaccb24df27bc95',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'sideboards',
						title: 'Sideboards &amp; Buffets',
						menu_title: 'Sideboards',
						html_title: 'Sideboards & Buffets',
						collection: '5ebdd28a9db0c4682f3881d0',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'furniture-shoe-storage',
						title: 'Shoe storage',
						menu_title: 'Shoe storage',
						html_title: 'Shoe storage',
						collection: '5ebdf77e9db0c4682f388244',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'sectionals',
						title: 'Sectionals',
						menu_title: 'Sectionals',
						html_title: 'Sectionals',
						collection: '5edabe2fbeaccb24df27bc7a',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'sofas',
						title: 'Sofas',
						menu_title: 'Sofas',
						html_title: 'Sofas',
						collection: '5ebddd399db0c4682f3881ec',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'tv-stands',
						title: 'TV stands',
						menu_title: 'TV stands',
						html_title: 'TV stands',
						collection: '5ebdd3d39db0c4682f3881d5',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'vanity-tables',
						title: 'Vanity tables',
						menu_title: 'Vanity tables',
						html_title: 'Vanity tables',
						collection: '5edacb5cbeaccb24df27bc8c',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'wine-racks-and-coolers',
						title: 'Wine racks & coolers',
						menu_title: 'Wine racks & coolers',
						html_title: 'Wine racks & coolers',
						collection: '5ebde1da9db0c4682f3881fd',
						template: 'collection',
						subcategories: null
					},
					
					
					
					
					
					
					{
						url: 'white-tv-stands',
						title: 'White TV stands',
						menu_title: 'White TV stands',
						html_title: 'White TV stands',
						collection: '5ee01d696688344b15664574',
						upsell_collection: '5ebdd3d39db0c4682f3881d5',
						template: 'collection',
						subcategories: null,
						show_in_menu: false,
					},
					
					{
						url: 'tv-stands-with-cabinets',
						title: 'TV stands with cabinets',
						menu_title: 'TV stands with cabinets',
						html_title: 'TV stands with cabinets',
						collection: '5ee022186688344b15664575',
						upsell_collection: '5ebdd3d39db0c4682f3881d5',
						template: 'collection',
						subcategories: null,
						show_in_menu: false,
					},
					
					{
						url: '70-inch-tv-stands',
						title: 'TV stands for 70" screens',
						menu_title: 'TV stands for 70"',
						html_title: 'TV stands for 70" screens',
						collection: '5ee028676688344b15664576',
						upsell_collection: '5ebdd3d39db0c4682f3881d5',
						template: 'collection',
						subcategories: null,
						show_in_menu: false,
					},
				]
			}
		
		]
	},

	{
		url: 'decor',
		title: 'Decor',
		menu_title: 'Decor',
		html_title: 'Decor',
		collection: null,
		template: 'subcategories',
		subcategories: [
			
			{
				url: 'decor-by-type',
				title: 'Decor by type',
				menu_title: 'By type',
				html_title: 'Decor by type',
				collection: null,
				template: 'subcategories',
				skip_to_subcategories: true,
				subcategories: [
					
					{
						url: 'accent-rugs',
						title: 'Accent rugs',
						menu_title: 'Accent rugs',
						html_title: 'Accent rugs',
						collection: '5f249b74170072398d0ce4dd',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'accent-storage',
						title: 'Accent storage',
						menu_title: 'Accent storage',
						html_title: 'Accent storage',
						collection: '60f0c8426c860d35503b99b1',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'area-rugs',
						title: 'Area rugs',
						menu_title: 'Area rugs',
						html_title: 'Area rugs',
						collection: '5edacdc3beaccb24df27bc90',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'decor-bathroom-mirrors',
						title: 'Bathroom mirrors',
						menu_title: 'Bathroom mirrors',
						html_title: 'Bathroom mirrors',
						collection: '5ebdde499db0c4682f3881ef',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'candle-holders',
						title: 'Candle holders',
						menu_title: 'Candle holders',
						html_title: 'Candle holders',
						collection: '5edacc9bbeaccb24df27bc8d',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'canvas-art',
						title: 'Canvas art',
						menu_title: 'Canvas art',
						html_title: 'Canvas art',
						collection: '60f0bfcd6c860d35503b99a6',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'chair-pads',
						title: 'Chair pads',
						menu_title: 'Chair pads',
						html_title: 'Chair pads',
						collection: '5f24aaa4170072398d0ce4f9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'clocks',
						title: 'Clocks',
						menu_title: 'Clocks',
						html_title: 'Clocks',
						collection: '5ebde5389db0c4682f388208',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'curtains',
						title: 'Curtains',
						menu_title: 'Curtains',
						html_title: 'Curtains',
						collection: '5f24a1ff170072398d0ce4e8',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'dimensional-art',
						title: 'Dimensional art',
						menu_title: 'Dimensional art',
						html_title: 'Dimensional art',
						collection: '60f0c0146c860d35503b99a7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'textile-door-panels',
						title: 'Textile door panels',
						menu_title: 'Door panels',
						html_title: 'Textile door panels',
						collection: '5f24a8ad170072398d0ce4f1',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'electric-fireplaces',
						title: 'Electric fireplaces',
						menu_title: 'Electric fireplaces',
						html_title: 'Electric fireplaces',
						collection: '5ebdd4179db0c4682f3881d7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'fireplace-mantels',
						title: 'Fireplace mantels',
						menu_title: 'Fireplace mantels',
						html_title: 'Fireplace mantels',
						collection: '5edad2dcbeaccb24df27bc99',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'framed-art',
						title: 'Framed art',
						menu_title: 'Framed art',
						html_title: 'Framed art',
						collection: '60f0c0946c860d35503b99a8',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'knobs',
						title: 'Knobs',
						menu_title: 'Knobs',
						html_title: 'Knobs',
						collection: '5edad233beaccb24df27bc98',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'decor-mirrors',
						title: 'Mirrors',
						menu_title: 'Mirrors',
						html_title: 'Mirrors',
						collection: '5ebdd45f9db0c4682f3881d8',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'panel-curtains',
						title: 'Panel curtains',
						menu_title: 'Panel curtains',
						html_title: 'Panel curtains',
						collection: '5f24a486170072398d0ce4ee',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'house-plants',
						title: 'House plants',
						menu_title: 'Plants',
						html_title: 'House plants',
						collection: '5ebde2ac9db0c4682f3881ff',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'decor-pillows',
						title: 'Decorative pillows',
						menu_title: 'Pillows',
						html_title: 'Decorative pillows',
						collection: '5edac710beaccb24df27bc85',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'decor-pillow-cases-and-covers',
						title: 'Pillow cases & covers',
						menu_title: 'Pillow cases & covers',
						html_title: 'Pillow cases & covers',
						collection: '5f24a2df170072398d0ce4e9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'prairie-swags-and-curtains',
						title: 'Prairie swags & curtains',
						menu_title: 'Prairie swags',
						html_title: 'Prairie swags & curtains',
						collection: '5f24a362170072398d0ce4eb',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'room-divider-screens',
						title: 'Room divider screens',
						menu_title: 'Room divider screens',
						html_title: 'Room divider screens',
						collection: '5ebdf1949db0c4682f38822a',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'rugs',
						title: 'Rugs',
						menu_title: 'Rugs',
						html_title: 'Rugs',
						collection: '5ebde5d19db0c4682f38820b',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'sculptures',
						title: 'Sculptures',
						menu_title: 'Sculptures',
						html_title: 'Sculptures',
						collection: '5edac58cbeaccb24df27bc83',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'stair-treads',
						title: 'Stair treads',
						menu_title: 'Stair treads',
						html_title: 'Stair treads',
						collection: '5f24aa1f170072398d0ce4f7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'swag-and-tier-curtains',
						title: 'Swag & tier curtains',
						menu_title: 'Swag & tier curtains',
						html_title: 'Swag & tier curtains',
						collection: '5f24a1ea170072398d0ce4e7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'textile',
						title: 'Textile',
						menu_title: 'Textile',
						html_title: 'Textile',
						collection: '5ebdd8819db0c4682f3881dd',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'throws',
						title: 'Throws',
						menu_title: 'Throws',
						html_title: 'Throws',
						collection: '5edac2e3beaccb24df27bc81',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'valances',
						title: 'Valances',
						menu_title: 'Valances',
						html_title: 'Valances',
						collection: '5f24a3a9170072398d0ce4ec',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'vases',
						title: 'Vases',
						menu_title: 'Vases',
						html_title: 'Vases',
						collection: '5edacd04beaccb24df27bc8e',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'wall-accents',
						title: 'Wall accents',
						menu_title: 'Wall accents',
						html_title: 'Wall accents',
						collection: '5ebdd3299db0c4682f3881d2',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'decor-wine-racks-and-coolers',
						title: 'Wine racks & coolers',
						menu_title: 'Wine racks & coolers',
						html_title: 'Wine racks & coolers',
						collection: '5ebde1da9db0c4682f3881fd',
						template: 'collection',
						subcategories: null
					},

				]
			},
			
			{
				url: 'decor-outdoor',
				title: 'Outdoor decor',
				menu_title: 'Outdoor decor',
				html_title: 'Outdoor decor',
				collection: null,
				template: 'subcategories',
				subcategories: [
					
					{
						url: 'decor-garden-bridges',
						title: 'Garden bridges',
						menu_title: 'Garden bridges',
						html_title: 'Garden bridges',
						collection: '5ebde43f9db0c4682f388204',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'decor-garden-statues',
						title: 'Garden statues',
						menu_title: 'Garden statues',
						html_title: 'Garden statues',
						collection: '5ebdf3ca9db0c4682f388233',
						template: 'collection',
						subcategories: null
					},

				]
			},
			
		]
	},

	{
		url: 'storage',
		title: 'Storage',
		menu_title: 'Storage',
		html_title: 'Storage',
		collection: null,
		template: 'subcategories',
		subcategories: [
			
			{
				url: 'storage-by-group',
				title: 'Storage by&nbsp;group',
				menu_title: 'By&nbsp;group',
				html_title: 'Storage by group',
				collection: null,
				template: 'subcategories',
				skip_to_subcategories: true,
				subcategories: [

					{
						url: 'storage-bedroom',
						title: 'Bedroom storage',
						menu_title: 'Bedroom storage',
						html_title: 'Bedroom storage',
						collection: '5ebdd06f9db0c4682f3881c7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'storage-office',
						title: 'Office storage',
						menu_title: 'Office storage',
						html_title: 'Office storage',
						collection: '5ebddd8e9db0c4682f3881ed',
						template: 'collection',
						subcategories: null
					},

				]
			},
			
			{
				url: 'storage-by-type',
				title: 'Storage by&nbsp;type',
				menu_title: 'By&nbsp;type',
				html_title: 'Storage by type',
				collection: null,
				template: 'subcategories',
				subcategories: [
					
					{
						url: 'storage-accent-chests-and-cabinets',
						title: 'Accent chests &amp; cabinets',
						menu_title: 'Accent chests',
						html_title: 'Accent chests & cabinets',
						collection: '60f0c8b76c860d35503b99b2',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'storage-dressers',
						title: 'Nightstands &amp; Dressers',
						menu_title: 'Dressers',
						html_title: 'Nightstands & Dressers',
						collection: '5ebdd4949db0c4682f3881d9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'jewelry-cabinets',
						title: 'Jewelry cabinets',
						menu_title: 'Jewelry cabinets',
						html_title: 'Jewelry cabinets',
						collection: '5ebded1a9db0c4682f388217',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'storage-nightstands',
						title: 'Nightstands &amp; Dressers',
						menu_title: 'Nightstands',
						html_title: 'Nightstands & Dressers',
						collection: '5ebdd4949db0c4682f3881d9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'safes',
						title: 'Safes',
						menu_title: 'Safes',
						html_title: 'Safes',
						collection: '5ebdf7289db0c4682f388242',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'shoe-storage',
						title: 'Shoe storage',
						menu_title: 'Shoe storage',
						html_title: 'Shoe storage',
						collection: '5ebdf77e9db0c4682f388244',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'wardrobes-and-armoires',
						title: 'Wardrobes &amp; Armoires',
						menu_title: 'Wardrobes &amp; Armoires',
						html_title: 'Wardrobes & Armoires',
						collection: '5ebdd0899db0c4682f3881c8',
						template: 'collection',
						subcategories: null
					},
					
				]
			},
			
		]
	},

	{
		url: 'lighting',
		title: 'Lighting',
		menu_title: 'Lighting',
		html_title: 'Lighting',
		collection: null,
		template: 'subcategories',
		subcategories: [
			
			{
				url: 'accent-lamps',
				title: 'Accent lamps',
				menu_title: 'Accent lamps',
				html_title: 'Accent lamps',
				collection: '5edab992beaccb24df27bc74',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'chandeliers',
				title: 'Chandeliers',
				menu_title: 'Chandeliers',
				html_title: 'Chandeliers',
				collection: '5ebdd2c89db0c4682f3881d1',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'ceiling-and-pendant-lighting',
				title: 'Ceiling &amp; pendant lighting',
				menu_title: 'Ceiling lighting',
				html_title: 'Ceiling & pendant lighting',
				collection: '5ebdf5f39db0c4682f38823a',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'floor-lamps',
				title: 'Floor lamps',
				menu_title: 'Floor lamps',
				html_title: 'Floor lamps',
				collection: '5ebdf1029db0c4682f388226',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'novelty-lamps',
				title: 'Novelty lamps',
				menu_title: 'Novelty lamps',
				html_title: 'Novelty lamps',
				collection: '5edabb0cbeaccb24df27bc76',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'pendants',
				title: 'Pendants',
				menu_title: 'Pendants',
				html_title: 'Pendants',
				collection: '60f0d2816c860d35503b99b3',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'solar-lighting',
				title: 'Solar lighting',
				menu_title: 'Solar',
				html_title: 'Solar lighting',
				collection: '5ebdf7459db0c4682f388243',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'table-lamps',
				title: 'Table lamps',
				menu_title: 'Table lamps',
				html_title: 'Table lamps',
				collection: '5ebdf2659db0c4682f38822f',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'torchiere-lamps',
				title: 'Torchiere lamps',
				menu_title: 'Torchiere lamps',
				html_title: 'Torchiere lamps',
				collection: '5edaba88beaccb24df27bc75',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'wall-lighting',
				title: 'Wall lighting',
				menu_title: 'Wall lighting',
				html_title: 'Wall lighting',
				collection: '5edabbf7beaccb24df27bc77',
				template: 'collection',
				subcategories: null
			},
			
			
			
			// Additional categories for search
			
			{
				url: 'tripod-floor-lamps',
				title: 'Tripod floor lamps',
				menu_title: 'Tripod floor lamps',
				html_title: 'Tripod floor lamps',
				collection: '5ee00c3e6688344b15664572',
				upsell_collection: '5ebdf1029db0c4682f388226',
				template: 'collection',
				subcategories: null,
				show_in_menu: false,
			},
			
		]
	},

	{
		url: 'office',
		title: 'Office furniture and&nbsp;decor',
		menu_title: 'Office',
		html_title: 'Office furniture and decor',
		collection: null,
		template: 'subcategories',
		subcategories: [

			{
				url: 'office-bookcases',
				title: 'Bookcases',
				menu_title: 'Bookcases',
				html_title: 'Bookcases',
				collection: '5ebdceee9db0c4682f3881c3',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'chair-mats',
				title: 'Chair mats',
				menu_title: 'Chair mats',
				html_title: 'Chair mats',
				collection: '5ebde5089db0c4682f388207',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'office-drawers',
				title: 'Office drawers',
				menu_title: 'Drawers',
				html_title: 'Office drawers',
				collection: '5edaca5ebeaccb24df27bc8a',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'filing-cabinets',
				title: 'Filing cabinets',
				menu_title: 'Filing cabinets',
				html_title: 'Filing cabinets',
				collection: '5ebdf0e79db0c4682f388225',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'letter-holders',
				title: 'Letter holders',
				menu_title: 'Letter holders',
				html_title: 'Letter holders',
				collection: '5ebdf5b29db0c4682f388239',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'printer-stands',
				title: 'Printer stands',
				menu_title: 'Printer stands',
				html_title: 'Printer stands',
				collection: '5ebdf0729db0c4682f388223',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'shelving-units',
				title: 'Shelving units',
				menu_title: 'Shelving units',
				html_title: 'Shelving units',
				collection: '5ebdef8a9db0c4682f38821e',
				template: 'collection',
				subcategories: null
			},
			
		]
	},

	// {
	// 	url: 'kids',
	// 	title: 'Kids furniture and&nbsp;decor',
	// 	menu_title: 'Kids',
	// 	html_title: 'Kids furniture and decor',
	// 	collection: null,
	// 	template: 'subcategories',
	// 	subcategories: [
			
	// 		{
	// 			url: 'kids-bedroom',
	// 			title: 'Kids bedroom furniture',
	// 			menu_title: 'Kids bedroom',
	// 			html_title: 'Kids bedroom furniture',
	// 			collection: '5ebe00039db0c4682f388252',
	// 			template: 'collection',
	// 			subcategories: null
	// 		},
			
	// 		{
	// 			url: 'kids-dressers',
	// 			title: 'Kids dressers',
	// 			menu_title: 'Kids dressers',
	// 			html_title: 'Kids dressers',
	// 			collection: '5ebe00039db0c4682f388252',
	// 			template: 'collection',
	// 			subcategories: null
	// 		},
			
	// 	]
	// },

	{
		url: 'bedroom',
		title: 'Bedroom furniture and&nbsp;decor',
		menu_title: 'Bedroom',
		html_title: 'Bedroom furniture and decor',
		collection: null,
		template: 'subcategories',
		image: 'bedroom.jpg',
		subcategories: [
			
			{
				url: 'bedroom-by-group',
				title: 'Bedroom products by group',
				menu_title: 'By group',
				html_title: 'Bedroom products by group',
				collection: '',
				template: 'subcategories',
				skip_to_subcategories: true,
				subcategories: [
					
					{
						url: 'bedding',
						title: 'Bedding',
						menu_title: 'Bedding',
						html_title: 'Bedding',
						collection: '5ebdd8a19db0c4682f3881de',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedroom-beds',
						title: 'Beds &amp; bed frames',
						menu_title: 'Beds &amp; bed frames',
						html_title: 'Beds & bed frames',
						collection: '5ebdda6a9db0c4682f3881e3',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'kids-bedding',
						title: 'Kids bedding',
						menu_title: 'Kids bedding',
						html_title: 'Kids bedding',
						collection: '5f24ab55170072398d0ce4fb',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'mattresses',
						title: 'Mattresses',
						menu_title: 'Mattresses',
						html_title: 'Mattresses',
						collection: '5ebdefb19db0c4682f38821f',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedroom-storage',
						title: 'Bedroom storage',
						menu_title: 'Bedroom storage',
						html_title: 'Bedroom storage',
						collection: '5ebdd06f9db0c4682f3881c7',
						template: 'collection',
						subcategories: null
					},
					
				]
			},
			
			{
				url: 'bedroom-by-type',
				title: 'Bedroom products by type',
				menu_title: 'By type',
				html_title: 'Bedroom products by type',
				collection: '',
				template: 'subcategories',
				subcategories: [
					
					{
						url: 'adjustable-beds',
						title: 'Adjustable beds',
						menu_title: 'Adjustable beds',
						html_title: 'Adjustable beds',
						collection: '5ebde10e9db0c4682f3881fa',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedroom-bed-skirts',
						title: 'Bed skirts',
						menu_title: 'Bed skirts',
						html_title: 'Bed skirts',
						collection: '5f249f99170072398d0ce4e5',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedspreads',
						title: 'Bedspreads',
						menu_title: 'Bedspreads',
						html_title: 'Bedspreads',
						collection: '5ebde18e9db0c4682f3881fc',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'blankets-and-quilts',
						title: 'Blankets &amp; quilts',
						menu_title: 'Blankets &amp; quilts',
						html_title: 'Blankets & quilts',
						collection: '5ebdd9259db0c4682f3881df',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bunk-beds',
						title: 'Bunk beds',
						menu_title: 'Bunk beds',
						html_title: 'Bunk beds',
						collection: '5ebde5f69db0c4682f38820c',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'canopy-beds',
						title: 'Canopy beds',
						menu_title: 'Canopy beds',
						html_title: 'Canopy beds',
						collection: '5ebde3a39db0c4682f388201',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'comforters-and-sets',
						title: 'Comforters &amp; sets',
						menu_title: 'Comforters &amp; sets',
						html_title: 'Comforters & sets',
						collection: '5ebddd079db0c4682f3881eb',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'comforter-and-duvet-fillers',
						title: 'Comforter &amp; duvet fillers',
						menu_title: 'Comforter &amp; duvet fillers',
						html_title: 'Comforter & duvet fillers',
						collection: '60f0c2566c860d35503b99aa',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'coverlets',
						title: 'Coverlets',
						menu_title: 'Coverlets',
						html_title: 'Coverlets',
						collection: '60f0bd316c860d35503b99a3',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'daybeds',
						title: 'Daybeds',
						menu_title: 'Daybeds',
						html_title: 'Daybeds',
						collection: '5ebddb869db0c4682f3881e8',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'daybed-covers',
						title: 'Daybed covers',
						menu_title: 'Daybed covers',
						html_title: 'Daybed covers',
						collection: '60f0bebe6c860d35503b99a5',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'duvet-covers',
						title: 'Duvet covers',
						menu_title: 'Duvet covers',
						html_title: 'Duvet covers',
						collection: '60f0bd496c860d35503b99a4',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedroom-headboards',
						title: 'Headboards',
						menu_title: 'Headboards',
						html_title: 'Headboards',
						collection: '5ebde0259db0c4682f3881f6',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'heated-bedding',
						title: 'Heated bedding',
						menu_title: 'Heated bedding',
						html_title: 'Heated bedding',
						collection: '60f0c6e16c860d35503b99b0',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedroom-mattresses',
						title: 'Mattresses',
						menu_title: 'Mattresses',
						html_title: 'Mattresses',
						collection: '5ebdefb19db0c4682f38821f',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'massage-tables',
						title: 'Massage tables',
						menu_title: 'Massage tables',
						html_title: 'Massage tables',
						collection: '5ebdf8d49db0c4682f38824a',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'mattress-pads',
						title: 'Mattress pads',
						menu_title: 'Mattress pads',
						html_title: 'Mattress pads',
						collection: '60f0c3386c860d35503b99ab',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'mattress-protectors',
						title: 'Mattress protectors',
						menu_title: 'Mattress protectors',
						html_title: 'Mattress protectors',
						collection: '60f0c4646c860d35503b99ac',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'mattress-toppers',
						title: 'Mattress toppers',
						menu_title: 'Mattress toppers',
						html_title: 'Mattress toppers',
						collection: '5ebdf24e9db0c4682f38822e',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'metal-beds',
						title: 'Metal beds',
						menu_title: 'Metal beds',
						html_title: 'Metal beds',
						collection: '5ebddfa69db0c4682f3881f4',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'pet-beds',
						title: 'Pet beds',
						menu_title: 'Pet beds',
						html_title: 'Pet beds',
						collection: '5ebdd96b9db0c4682f3881e0',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'pillows',
						title: 'Pillows',
						menu_title: 'Pillows',
						html_title: 'Pillows',
						collection: '5ebdf6d19db0c4682f38823f',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'platform-beds',
						title: 'Platform beds',
						menu_title: 'Platform beds',
						html_title: 'Platform beds',
						collection: '5ebddad69db0c4682f3881e4',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'sheets-and-sheet-sets',
						title: 'Sheets &amp; sheet sets',
						menu_title: 'Sheets &amp; sheet sets',
						html_title: 'Sheets & sheet sets',
						collection: '5ebdf5339db0c4682f388236',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'toddler-bedding',
						title: 'Toddler bedding',
						menu_title: 'Toddler bedding',
						html_title: 'Toddler bedding',
						collection: '5f24ab5d170072398d0ce4fc',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'upholstered-beds',
						title: 'Upholstered beds',
						menu_title: 'Upholstered beds',
						html_title: 'Upholstered beds',
						collection: '5ebdefd89db0c4682f388220',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bedroom-vanity-tables',
						title: 'Vanity tables',
						menu_title: 'Vanity tables',
						html_title: 'Vanity tables',
						collection: '5edacb5cbeaccb24df27bc8c',
						template: 'collection',
						subcategories: null
					},

				]
			},
			
		]
	},

	{
		url: 'bathroom',
		title: 'Bathroom furniture and&nbsp;decor',
		menu_title: 'Bathroom',
		html_title: 'Bathroom furniture and decor',
		collection: null,
		template: 'subcategories',
		subcategories: [
			
			{
				url: 'bathroom-accessories',
				title: 'Bathroom accessories',
				menu_title: 'Accessories',
				html_title: 'Bathroom accessories',
				collection: '60f0c5d46c860d35503b99ae',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'bathroom-sinks',
				title: 'Bathroom sinks',
				menu_title: 'Bathroom sinks',
				html_title: 'Bathroom sinks',
				collection: '5ebdeeec9db0c4682f38821c',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'bathroom-rugs',
				title: 'Bathroom rugs',
				menu_title: 'Bathroom rugs',
				html_title: 'Bathroom rugs',
				collection: '60f0c6416c860d35503b99af',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'bathroom-vanities',
				title: 'Bathroom vanities',
				menu_title: 'Bathroom vanities',
				html_title: 'Bathroom vanities',
				collection: '5ebdf4539db0c4682f388234',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'laundry-hampers',
				title: 'Laundry hampers',
				menu_title: 'Laundry hampers',
				html_title: 'Laundry hampers',
				collection: '5ebdeeb69db0c4682f38821b',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'bathroom-mirrors',
				title: 'Bathroom mirrors',
				menu_title: 'Mirrors',
				html_title: 'Bathroom mirrors',
				collection: '5ebdde499db0c4682f3881ef',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'bathroom-towels',
				title: 'Bathroom towels',
				menu_title: 'Towels',
				html_title: 'Bathroom towels',
				collection: '60f0c50d6c860d35503b99ad',
				template: 'collection',
				subcategories: null
			},

			{
				url: 'shower-curtains',
				title: 'Shower curtains',
				menu_title: 'Shower curtains',
				html_title: 'Shower curtains',
				collection: '5ebdf56f9db0c4682f388237',
				template: 'collection',
				subcategories: null
			},
			
		]
	},
	
	{
		url: 'kitchen',
		title: 'Kitchen furniture and&nbsp;decor',
		menu_title: 'Kitchen',
		html_title: 'Kitchen furniture and decor',
		collection: null,
		template: 'subcategories',
		subcategories: [
			
			{
				url: 'bakers-racks',
				title: 'Bakers racks',
				menu_title: 'Bakers racks',
				html_title: 'Bakers racks',
				collection: '5ebdf1e89db0c4682f38822c',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'blenders',
				title: 'Blenders',
				menu_title: 'Blenders',
				html_title: 'Blenders',
				collection: '5ebdf4be9db0c4682f388235',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'kitchen-cabinets',
				title: 'Kitchen cabinets',
				menu_title: 'Kitchen cabinets',
				html_title: 'Kitchen cabinets',
				collection: '5edac797beaccb24df27bc86',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'cookware-sets',
				title: 'Cookware sets',
				menu_title: 'Cookware sets',
				html_title: 'Cookware sets',
				collection: '5ebdf13c9db0c4682f388228',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'kitchen-furniture',
				title: 'Kitchen furniture',
				menu_title: 'Furniture',
				html_title: 'Kitchen furniture',
				collection: '5ebdd6ff9db0c4682f3881db',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'frying-pans',
				title: 'Frying pans',
				menu_title: 'Frying pans',
				html_title: 'Frying pans',
				collection: '5ebdf6469db0c4682f38823c',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'juicers',
				title: 'Juicers',
				menu_title: 'Juicers',
				html_title: 'Juicers',
				collection: '5ebdf2cd9db0c4682f388231',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'kitchen-carts',
				title: 'Kitchen carts',
				menu_title: 'Kitchen carts',
				html_title: 'Kitchen carts',
				collection: '5ebdd7199db0c4682f3881dc',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'placemats-and-napkins',
				title: 'Placemats & Napkins',
				menu_title: 'Placemats & napkins',
				html_title: 'Placemats & Napkins',
				collection: '5f249e43170072398d0ce4e1',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'pot-racks',
				title: 'Pot racks',
				menu_title: 'Pot racks',
				html_title: 'Pot racks',
				collection: '5ebdf2229db0c4682f38822d',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'refrigerators-and-mini-fridges',
				title: 'Refrigerators &amp; mini fridges',
				menu_title: 'Refrigerators',
				html_title: 'Refrigerators & mini fridges',
				collection: '5ebdf7119db0c4682f388241',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'rice-cookers',
				title: 'Rice cookers',
				menu_title: 'Rice cookers',
				html_title: 'Rice cookers',
				collection: '5ebdf1b69db0c4682f38822b',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'table-runners',
				title: 'Table runners',
				menu_title: 'Table runners',
				html_title: 'Table runners',
				collection: '5f249fe3170072398d0ce4e6',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'tabletop',
				title: 'Tabletop products',
				menu_title: 'Tabletop',
				html_title: 'Tabletop products',
				collection: '5f249e7c170072398d0ce4e2',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'table-cloths-and-toppers',
				title: 'Table cloths &amp; toppers',
				menu_title: 'Table cloths',
				html_title: 'Table cloths & toppers',
				collection: '5f24a92e170072398d0ce4f2',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'teapots',
				title: 'Teapots',
				menu_title: 'Teapots',
				html_title: 'Teapots',
				collection: '5ebdef339db0c4682f38821d',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'kitchen-towels',
				title: 'Kitchen towels',
				menu_title: 'Towels',
				html_title: 'Kitchen towels',
				collection: '5f24a325170072398d0ce4ea',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'trivets-and-coasters',
				title: 'Trivets & coasters',
				menu_title: 'Trivets & coasters',
				html_title: 'Trivets & coasters',
				collection: '5f24a841170072398d0ce4f0',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'trash-cans-and-recycle-bins',
				title: 'Trash cans &amp; recycle bins',
				menu_title: 'Trash &amp; recycle bins',
				html_title: 'Trash cans & recycle bins',
				collection: '5ebdf6ae9db0c4682f38823e',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'kitchen-trays',
				title: 'Kitchen trays',
				menu_title: 'Trays',
				html_title: 'Kitchen trays',
				collection: '5edacd5bbeaccb24df27bc8f',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'utility-tables-and-workbenches',
				title: 'Utility tables &amp; workbenches',
				menu_title: 'Utility tables',
				html_title: 'Utility tables & workbenches',
				collection: '5ebdf0449db0c4682f388222',
				template: 'collection',
				subcategories: null
			},
			
		]
	},

	{
		url: 'outdoor',
		title: 'Outdoor furniture and&nbsp;decor',
		menu_title: 'Outdoor',
		html_title: 'Outdoor furniture and decor',
		collection: null,
		template: 'subcategories',
		subcategories: [
			
			{
				url: 'outdoor-by-group',
				title: 'Outdoor products by group',
				menu_title: 'By group',
				html_title: 'Outdoor products by group',
				collection: null,
				template: 'subcategories',
				skip_to_subcategories: true,
				subcategories: [
					
					{
						url: 'outdoor-furniture',
						title: 'Outdoor furniture',
						menu_title: 'Outdoor furniture',
						html_title: 'Outdoor furniture',
						collection: '5ebdcf7b9db0c4682f3881c5',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'gardening',
						title: 'Gardening',
						menu_title: 'Gardening',
						html_title: 'Gardening',
						collection: '5ec4718f73013e394a07be53',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'outdoor-storage',
						title: 'Outdoor storage',
						menu_title: 'Outdoor storage',
						html_title: 'Outdoor storage',
						collection: '5ebdda199db0c4682f3881e1',
						template: 'collection',
						subcategories: null
					},
					
				]
			},
			
			{
				url: 'outdoor-by-type',
				title: 'Outdoor products by type',
				menu_title: 'By type',
				html_title: 'Outdoor products by type',
				collection: '',
				template: 'subcategories',
				subcategories: [
					
					{
						url: 'adirondack-chairs',
						title: 'Adirondack chairs',
						menu_title: 'Adirondack chairs',
						html_title: 'Adirondack chairs',
						collection: '5ebde5b99db0c4682f38820a',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'beach-bikes',
						title: 'Beach bikes',
						menu_title: 'Beach bikes',
						html_title: 'Beach bikes',
						collection: '5ebdf62b9db0c4682f38823b',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'beach-chairs',
						title: 'Beach chairs',
						menu_title: 'Beach chairs',
						html_title: 'Beach chairs',
						collection: '5ebdf2f79db0c4682f388232',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bird-feeders',
						title: 'Bird feeders',
						menu_title: 'Bird feeders',
						html_title: 'Bird feeders',
						collection: '5ebdf8239db0c4682f388246',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'bird-houses',
						title: 'Bird houses',
						menu_title: 'Bird houses',
						html_title: 'Bird houses',
						collection: '5ebdf6ed9db0c4682f388240',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'garden-benches',
						title: 'Outdoor &amp; garden benches',
						menu_title: 'Benches',
						html_title: 'Outdoor & garden benches',
						collection: '5ebde4169db0c4682f388203',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'canoes',
						title: 'Canoes',
						menu_title: 'Canoes',
						html_title: 'Canoes',
						collection: '5f0b649d7d4d792218570ac2',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'carts-and-wheelbarrows',
						title: 'Carts &amp; wheelbarrows',
						menu_title: 'Carts &amp; wheelbarrows',
						html_title: 'Carts & wheelbarrows',
						collection: '5ebde3559db0c4682f388200',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'compost-bins',
						title: 'Compost bins',
						menu_title: 'Compost bins',
						html_title: 'Compost bins',
						collection: '5ebddb239db0c4682f3881e6',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'outdoor-cushions-and-poufs',
						title: 'Outdoor cushions &amp; poufs',
						menu_title: 'Cushions &amp; poufs',
						html_title: 'Outdoor cushions & poufs',
						collection: '60f0d9116c860d35503b99b7',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'outdoor-decorative-pillows',
						title: 'Outdoor decorative pillows',
						menu_title: 'Decorative pillows',
						html_title: 'Outdoor decorative pillows',
						collection: '60f0da056c860d35503b99b9',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'firewood-racks',
						title: 'Firewood racks',
						menu_title: 'Firewood racks',
						html_title: 'Firewood racks',
						collection: '5ebdf5929db0c4682f388238',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'fire-pits',
						title: 'Fire pits',
						menu_title: 'Fire pits',
						html_title: 'Fire pits',
						collection: '5ebddff69db0c4682f3881f5',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'fountains',
						title: 'Fountains',
						menu_title: 'Fountains',
						html_title: 'Fountains',
						collection: '5ebdf11d9db0c4682f388227',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'garden-bridges',
						title: 'Garden bridges',
						menu_title: 'Garden bridges',
						html_title: 'Garden bridges',
						collection: '5ebde43f9db0c4682f388204',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'garden-statues',
						title: 'Garden statues',
						menu_title: 'Garden statues',
						html_title: 'Garden statues',
						collection: '5ebdf3ca9db0c4682f388233',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'gazebos-and-canopies',
						title: 'Gazebos &amp; canopies',
						menu_title: 'Gazebos &amp; canopies',
						html_title: 'Gazebos & canopies',
						collection: '5ebde4c59db0c4682f388206',
						template: 'collection',
						subcategories: null
					},

					{
						url: 'greenhouses',
						title: 'Greenhouses',
						menu_title: 'Greenhouses',
						html_title: 'Greenhouses',
						collection: '5ebdee649db0c4682f38821a',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'hammocks',
						title: 'Hammocks',
						menu_title: 'Hammocks',
						html_title: 'Hammocks',
						collection: '5ebdeca89db0c4682f388215',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'lawn-mowers',
						title: 'Lawn mowers',
						menu_title: 'Lawn mowers',
						html_title: 'Lawn mowers',
						collection: '5ebdf9329db0c4682f38824b',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'outdoor-lighting',
						title: 'Outdoor lighting',
						menu_title: 'Lighting',
						html_title: 'Outdoor lighting',
						collection: '5ebdfa2a9db0c4682f38824f',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'patio-chaise-lounge-chairs',
						title: 'Patio chaise lounge chairs',
						menu_title: 'Patio chaise lounge chairs',
						html_title: 'Patio chaise lounge chairs',
						collection: '5ebdf6879db0c4682f38823d',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'patio-sets',
						title: 'Patio furniture sets',
						menu_title: 'Patio sets',
						html_title: 'Patio furniture sets',
						collection: '5ebddb639db0c4682f3881e7',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'pet-houses',
						title: 'Pet houses',
						menu_title: 'Pet houses',
						html_title: 'Pet houses',
						collection: '5ebdd3959db0c4682f3881d4',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'planters',
						title: 'Planters',
						menu_title: 'Planters',
						html_title: 'Planters',
						collection: '5ebde5849db0c4682f388209',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'potting-benches',
						title: 'Potting benches',
						menu_title: 'Potting benches',
						html_title: 'Potting benches',
						collection: '5ebdee249db0c4682f388218',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'rain-barrels',
						title: 'Rain barrels',
						menu_title: 'Rain barrels',
						html_title: 'Rain barrels',
						collection: '5ebdea839db0c4682f388210',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'rain-chains',
						title: 'Rain chains',
						menu_title: 'Rain chains',
						html_title: 'Rain chains',
						collection: '5ebdec5e9db0c4682f388213',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'outdoor-rugs',
						title: 'Outdoor rugs',
						menu_title: 'Rugs',
						html_title: 'Outdoor rugs',
						collection: '5ebdf0a89db0c4682f388224',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'storage-sheds',
						title: 'Storage sheds',
						menu_title: 'Storage sheds',
						html_title: 'Storage sheds',
						collection: '5ebdda2a9db0c4682f3881e2',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'swings-and-gliders',
						title: 'Swings &amp; gliders',
						menu_title: 'Swings &amp; gliders',
						html_title: 'Swings & gliders',
						collection: '5ebdead99db0c4682f388211',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'outdoor-tables',
						title: 'Outdoor &amp; patio tables',
						menu_title: 'Tables',
						html_title: 'Outdoor & patio tables',
						collection: '5ebdec889db0c4682f388214',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'trellises',
						title: 'Trellises',
						menu_title: 'Trellises',
						html_title: 'Trellises',
						collection: '5ebde4959db0c4682f388205',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'patio-umbrellas',
						title: 'Patio umbrellas',
						menu_title: 'Umbrellas',
						html_title: 'Patio umbrellas',
						collection: '5ebdeafe9db0c4682f388212',
						template: 'collection',
						subcategories: null
					},
					
					{
						url: 'wind-spinners',
						title: 'Wind spinners',
						menu_title: 'Wind spinners',
						html_title: 'Wind spinners',
						collection: '5ebdf0149db0c4682f388221',
						template: 'collection',
						subcategories: null
					},
					
				]
			},
			
		]
	},
	
	{
		url: 'game-room',
		title: 'Game room',
		menu_title: 'Game room',
		html_title: 'Game room',
		collection: null,
		template: 'subcategories',
		show_in_main_menu: false,
		subcategories: [
			
			{
				url: 'games',
				title: 'All games',
				menu_title: 'All games',
				html_title: 'All games',
				collection: '5ebddbb79db0c4682f3881e9',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'dart-boards',
				title: 'Dart boards',
				menu_title: 'Dart boards',
				html_title: 'Dart boards',
				collection: '5ebdf8559db0c4682f388247',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'educational-science-kits',
				title: 'Educational science kits',
				menu_title: 'Educational science kits',
				html_title: 'Educational science kits',
				collection: '5ebdf87b9db0c4682f388248',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'foosball',
				title: 'Foosball tables &amp; accessories',
				menu_title: 'Foosball',
				html_title: 'Foosball tables & accessories',
				collection: '5ebdea1f9db0c4682f38820f',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'poker-tables',
				title: 'Poker tables',
				menu_title: 'Poker tables',
				html_title: 'Poker tables',
				collection: '5ebddc579db0c4682f3881ea',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'pool-tables',
				title: 'Pool tables',
				menu_title: 'Pool tables',
				html_title: 'Pool tables',
				collection: '5ebdf9ed9db0c4682f38824e',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'roulette',
				title: 'Roulette',
				menu_title: 'Roulette',
				html_title: 'Roulette',
				collection: '5ebdf8b39db0c4682f388249',
				template: 'collection',
				subcategories: null
			},
			
		]
	},
	
	{
		url: 'sports-and-gym',
		title: 'Sports &amp; gym',
		menu_title: 'Sports &amp; gym',
		html_title: 'Sports & gym',
		collection: null,
		template: 'subcategories',
		show_in_main_menu: false,
		show_in_menu: false,
		subcategories: [
			
			{
				url: 'fitness-equipment',
				title: 'Fitness equipment',
				menu_title: 'Fitness equipment',
				html_title: 'Fitness equipment',
				collection: '5ebddeba9db0c4682f3881f2',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'inversion-tables',
				title: 'Inversion tables',
				menu_title: 'Inversion tables',
				html_title: 'Inversion tables',
				collection: '5ebdfabe9db0c4682f388250',
				template: 'collection',
				subcategories: null
			},
			
		]
	},
	
	{
		url: 'personal-accessories',
		title: 'Personal accessories',
		menu_title: 'Personal',
		html_title: 'Personal accessories',
		collection: null,
		template: 'subcategories',
		show_in_main_menu: false,
		show_in_menu: false,
		subcategories: [

			{
				url: 'crossbody-bags',
				title: 'Crossbody bags',
				menu_title: 'Crossbody bags',
				html_title: 'Crossbody bags',
				collection: '5f24a9b5170072398d0ce4f5',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'hobo-bags',
				title: 'Hobo bags',
				menu_title: 'Hobo bags',
				html_title: 'Hobo bags',
				collection: '5f24aaca170072398d0ce4fa',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'overnight-bags',
				title: 'Overnight bags',
				menu_title: 'Overnight bags',
				html_title: 'Overnight bags',
				collection: '5f24ab8a170072398d0ce4fd',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'shoulder-bags',
				title: 'Shoulder bags',
				menu_title: 'Shoulder bags',
				html_title: 'Shoulder bags',
				collection: '5f24a9e1170072398d0ce4f6',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'tote-bags',
				title: 'Tote bags',
				menu_title: 'Tote bags',
				html_title: 'Tote bags',
				collection: '5f24aa4b170072398d0ce4f8',
				template: 'collection',
				subcategories: null
			},
			
			{
				url: 'wallets',
				title: 'Wallets',
				menu_title: 'Wallets',
				html_title: 'Wallets',
				collection: '5f24a986170072398d0ce4f4',
				template: 'collection',
				subcategories: null
			},

		]
	},
	
	{
		url: 'christmas',
		title: 'Christmas decorations &amp; supplies',
		menu_title: 'Christmas',
		html_title: 'Christmas decorations & supplies',
		collection: null,
		template: 'subcategories',
		class: 'highlight',
		show_in_main_menu: false,
		show_in_menu: false,
		subcategories: [
			
			{
				url: 'christmas-trees',
				title: 'Christmas trees',
				menu_title: 'Christmas trees',
				html_title: 'Christmas trees',
				collection: '5ebddebc9db0c4682f3881f3',
				template: 'collection',
				subcategories: null
			},
			
		]
	},
	
	{
		url: 'seasonal',
		title: 'Seasonal decorations & supplies',
		menu_title: 'Seasonal',
		html_title: 'Seasonal decorations & supplies',
		collection: null,
		template: 'subcategories',
		class: 'highlight',
		show_in_main_menu: false,
		show_in_menu: true,
		subcategories: [

			{
				url: 'seasonal-decor',
				title: 'Seasonal decor',
				menu_title: 'Seasonal decor',
				html_title: 'Seasonal decor',
				collection: '5f24a506170072398d0ce4ef',
				template: 'collection',
				subcategories: null,
			},
			
			{
				url: 'seasonal-stockings',
				title: 'Seasonal stockings',
				menu_title: 'Stockings',
				html_title: 'Seasonal stockings',
				collection: '5f24a439170072398d0ce4ed',
				template: 'collection',
				subcategories: null,
			},
			
			{
				url: 'tree-skirts',
				title: 'Tree skirts',
				menu_title: 'Tree skirts',
				html_title: 'Tree skirts',
				collection: '5f249efe170072398d0ce4e4',
				template: 'collection',
				subcategories: null,
			},

		]
	},

	// {
	// 	url: 'sale',
	// 	title: 'Furniture and&nbsp;decor on sale',
	// 	menu_title: 'Sale',
	// 	html_title: 'Furniture and decor on sale',
	// 	collection: null,
	// 	template: 'collection',
	// 	subcategories: [

	// 	]
	// },

	// {
	// 	url: 'july-4-supplies',
	// 	title: 'Independence day supplies',
	// 	menu_title: 'July 4',
	// 	html_title: 'Independence day supplies',
	// 	collection: null,
	// 	template: 'subcategories',
	// 	class: 'highlight',
	// 	subcategories: [

	// 	]
	// },

]

let parents = []
function fillCategories(categories)
{
	if (!categories)
		return null
	
	for (let i=0; i<categories.length; i++)
	{
		categories[i].parents = [ ...parents ]
		
		if (categories[i].subcategories)
		{
			parents.push(categories[i].url)
			categories[i].subcategories = fillCategories(categories[i].subcategories)
			parents = [ ...categories[i].parents ]
		}
	}

	return categories
}

function renderCategoriesAsTree(categories)
{
	return fillCategories(categories)
}

function renderCategoriesByUrl(categories)
{
	const result = {}
	const stack = [...categories]
	while (stack.length)
	{
		const node = stack.shift()
		result[node.url] = node
		
		if (node.subcategories)
			stack.push(...node.subcategories)
	}
	
	return result
}

// export const categories = renderCategoriesAsTree(categories_data)
// export const categories_by_url = renderCategoriesByUrl(categories)
export const categories = renderCategoriesAsTree(categories_data)
export const categories_by_url = renderCategoriesByUrl(categories)

// module.exports = {
// 	categories: categories,
// 	categories_by_url: categories_by_url,
// }

export default {
	categories: categories,
	categories_by_url: categories_by_url,
}