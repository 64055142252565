<template>
<div :class="[
		'modal-container',
		{ 'with-header': withHeader },
		{ 'compact': compact },
		{ 'locked': !active },
		{ 'hide-header': modals_count > 1 && !active && !show_cart }
	]" :key="modal_id" ref="modal_container" @click="close">
	<Header class="modal-page-header" v-if="withHeader" />
	<!-- <div class="modal-background" @click="close()"></div> -->
	<div class="modal">
		<slot></slot>
	</div>
	<slot name="outside"></slot>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Header from '~/components/common/Header'

export default {
	components: {
		Header
	},

	data: function()
	{
		return {
			modal_id: 0,
		}
	},

	props: {
		withHeader: { type: Boolean, default: false },
		compact: { type: Boolean, default: false },
	},

	computed: {
		...mapState({
			modals_count: state => state.modals_count,
			show_cart: state => state.show_cart,
		}),

		active()
		{
			return this.modal_id >= this.modals_count
		},
	},

	methods: {
		...mapActions([
			'updateModalsCount'
		]),

		close(event)
		{
			if (!event || event.target == this.$refs.modal_container)
				this.$emit('close')
		},

		keyboardEvent: function(event)
		{
			if (!event)
				return

			switch (event.keyCode)
			{
				case 27:
					if (this.active)
						this.close()
				return
			}
		},
	},

	mounted()
	{
		this.modal_id = this.modals_count + 1
		this.updateModalsCount(this.modal_id)
		window.addEventListener('keydown', this.keyboardEvent)
		document.body.style.overflow = 'hidden'
		this.$refs.modal_container.top = window.scrollY
	},

	destroyed()
	{
		if (!this.modals_count)
			return
		
		if (this.modals_count == 1)
			document.body.style.overflow = 'auto'
		
		this.updateModalsCount(this.modals_count - 1)
		window.removeEventListener('keydown', this.keyboardEvent)
	},
}
</script>

<style lang="scss">
.modal .header {

	.right {
		text-align: right;
		padding-top: 5px;
	}
}

.close-modal {
	color: #000;
	text-decoration: none;
	font-size: 36px;
	line-height: 13px;
	cursor: pointer;
	
	position: absolute;
	top: 15px;
	right: 12px;

	&:hover {
		color: $hover-color;
	}
}
</style>

<style lang="scss" scoped>
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

.modal-container {
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	// position: absolute;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(7px);
	cursor: pointer;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10000;

	&.with-header {
		.modal {
			margin: 20px 0;
			
			@include media-breakpoint-down(xs) {
				margin: 0;
			}
		}
	}

	&.hide-header {
		.modal-page-header {
			visibility: hidden;
		}
	}

	&.locked {
		overflow: hidden;
	}
	
	&.compact {
		.modal {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
			
			transition: all 0.2s;
		}
	}

	.modal-background {
		background: rgba(0, 0, 0, 0.54);
		background: rgba(0, 0, 0, 0.4);
		backdrop-filter: blur(7px);
		position: fixed;
		// position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		cursor: pointer;
		// animation: fadeIn 0.15s; // TODO: enable animation only on client to get rid of flickering on first SSR load
	}

	.modal {
		width: auto;
		background: #fff;
		position: absolute;
		// overflow-y: scroll;
		left: 85px;
		right: 85px;
		cursor: default;

		padding: 15px;
		margin: 3% 0;

		box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.5);

		@include media-breakpoint-down(xl) {
			left: 80px;
			right: 80px;
		}

		@include media-breakpoint-down(lg) {
			left: 50px;
			right: 50px;
			padding: 10px 5px;
		}

		@include media-breakpoint-down(md) {
			left: 2%;
			right: 2%;
		}
		
		@include media-breakpoint-down(md) {
			padding: 5px;
		}

		@include media-breakpoint-down(xs) {
			left: 0;
			right: 0;
			margin: 0;
			padding: 0;
		}
	}

	&.no-spacing .modal {
		padding: 0;
	}
}
</style>