const InputToLocalStorage = {
	methods: {
		saveToLocalStorage(name, value)
		{
			if (typeof localStorage === 'object')
				localStorage[name] = JSON.stringify(value)
			// else
				// console.log('No localStorage')
		},
		
		getFromLocalStorage(name)
		{
			if (typeof localStorage === 'object' && typeof localStorage[name] === 'string')
			{
				return JSON.parse(localStorage[name])
			}
		},
		
		removeFromLocalStorage(name)
		{
			if (typeof localStorage === 'object' && typeof localStorage[name] === 'string')
			{
				delete localStorage[name]
			}
		},
	}
}

export default InputToLocalStorage