const _throttle = require('lodash').throttle

import { mapState, mapActions } from 'vuex'

const CartCalculation = {
	data: () => ({
		tax: 0,
		tax_rate: 0,
		tax_calculated: false,
		subtotal_with_discounts: 0,
		total: 0,
		
		taxCalcFunction: null,
		calculateCart: null, // throttled function
		
		retries: 0,
	}),
	
	computed: {
		...mapState({
			cart: (state: any) => state.customer.cart,
			// cart_products: (state: any) => state.cart_products,
			checkout: (state: any) => state.customer.checkout,
			shipping_address: (state: any) => state.customer.checkout.shipping_address,
		}),
		
		subtotal: function()
		{
			if (this.subtotal_with_discounts)
				return this.subtotal_with_discounts
			
			return this.cart.reduce((sum, item) => {
				let c = 0
				if (item.available)
				{
					c = item.quantity * item.cart_price
				}
				return sum + c
			}, 0)
		},
		
		original_subtotal: function()
		{
			return this.cart.reduce((sum, item) =>
			{
				let c = 0
				if (item.available)
				{
					c = item.quantity * (item.original_price || item.list_price)
				}
				return sum + c
			}, 0)
		},
		
		total_discount: function()
		{
			if (this.subtotal_with_discounts && this.subtotal && this.subtotal > this.subtotal_with_discounts)
				return this.subtotal - this.subtotal_with_discounts
			
			return this.cart.reduce((sum, item) => {
				let c = 0
				if (item.available)
				{
					const original_price = item.original_price || item.list_price
					c = item.quantity * (original_price - item.cart_price)
				}
				return sum + c
			}, 0)
		},
	},
	
	methods: {
		...mapActions([
			'updateCheckoutData',
		]),
		
		calculateTax(zip)
		{
			if (!this.taxCalcFunction)
			{
				this.taxCalcFunction = _throttle(async (zip) =>
				{
					const result = await this.$api.calculateTax({ zip: zip })
					// console.log({ zip: zip }, result)
					return result
				}, 150)
			}
			
			return this.taxCalcFunction(zip)
		},
		
		setupCartCalculation()
		{
			if (!this.calculateCart)
			{
				this.calculateCart = _throttle(async () =>
				{
					if (!this.cart.length)
						return
					
					if (!this.checkout.shipping_address || !this.checkout.shipping_address.zip)
						return
					
					const result = await this.$api.calculateCart({
						cart: this.cart,
						email: this.checkout.email,
						full_name: this.checkout.full_name,
						shipping_address: this.checkout.shipping_address,
					})
					
					if (!result || result.status != 'ok')
					{
						if (result && result.error == 'throttle: previous request is still being processed')
						{
							setTimeout(this.calculateCart, 50)
							return
						}
						
						// Try again
						console.error('Cart calculation failed')
						this.retries++
						// if (this.retries > 2)
							// window.location.reload()
						setTimeout(this.calculateCart, 50)
						
						this.$track.event({
							gtm: {
								category: 'Error',
								action: 'Cart calculation error',
								label: 'Cart calculation failed',
							},
						})
						
						return
					}
					
					// TODO: save calculated total, subtotal, tax, stripe payment intent client secret, etc
					// console.log(result)
					
					this.tax_rate = result.tax_rate
					this.taxable_part = result.tax_rate
					this.tax = result.tax
					this.subtotal_with_discounts = result.subtotal
					this.total = result.total
					this.tax_calculated = true
					
					if (result.payment_intent)
					{
						this.updateCheckoutData({
							payment_intent: {
								id: result.payment_intent.id,
								client_secret: result.payment_intent.client_secret,
							},
						})
					}
					else
					{
						// Try again
						console.error('Something went wrong: no payment intent after cart calculation', result)
						this.retries++
						// if (this.retries > 2)
							// window.location.reload()
						setTimeout(this.calculateCart, 50)
						
						this.$track.event({
							gtm: {
								category: 'Error',
								action: 'Cart calculation error',
								label: 'No payment intent after cart calculation',
							},
						})
					}
					
				}, 1000)
			}

			return this.calculateCart
		},
	},
	
	mounted()
	{
		this.setupCartCalculation()
	},
	
	watch: {
		'shipping_address': {
			deep: true,
			handler: async function(new_address, old_address)
			{	
				this.calculateCart()
			}
		},
	},
}

export default CartCalculation