<template>
<div class="account-controls-container">
	<span class="d-sm-none header-link with-gradient" v-if="false">
		<font-awesome-icon :icon="['far', 'search']" />
	</span><AccountLink class="account-link" /><SavedLink :class="[ { 'd-none': !saved.length }, 'd-sm-inline-block' ]" /><CartLink />
</div>
</template>

<script>
import { mapState } from 'vuex'

import CartLink from '~/components/checkout/CartLink'
import SavedLink from '~/components/checkout/SavedLink'
import AccountLink from '~/components/signup/AccountLink'

export default {
	components: {
		CartLink,
		SavedLink,
		AccountLink,
	},
	
	computed: {
		...mapState({
			saved: state => state.customer.lists[0] ? state.customer.lists[0].items : [],
		}),
	},
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.account-controls-container {
	text-align: right;
	font-size: 14px;
	padding-top: 13px;
	// position: relative;
	white-space: nowrap;
	
	@include media-breakpoint-down(md) {
		margin-left: -15px;
		// background: #f00;
	}
	
	@include media-breakpoint-down(xs) {
		padding: 0;
	}

	.fa, .fas, .far, .fal, .svg-inline--fa {
		font-size: 10px;
		margin-right: 2px;
		
		@include media-breakpoint-down(xs) {
			font-size: 18px;
		}
	}

	a,
	.header-link {
		@include black_link;
		margin-left: 40px;
		white-space: nowrap;
		cursor: pointer;
		position: relative;

		@include media-breakpoint-down(xl) {
			margin-left: 25px;
		}
		
		// @include media-breakpoint-down(md) {
		// 	margin-left: 25px;
		// }
		
		@include media-breakpoint-down(xs) {
			display: inline-block;
			
			margin: 0;
			padding: 12px 6%;
			background: #fff;
			
			// position: relative;
			z-index: 2;
			
			&.account-link {
				z-index: 3;
			}
			
			&.with-gradient {
				background-color: none;
				background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 20%);
				padding-left: 25px;
			}
		}
		
		@include media-breakpoint-down(xxs) {
			padding: 12px 4.5%;
			
			// &.with-gradient {
			// }
		}

		// &:first-child {
		// 	margin-left: 0;
		// }
	}
}
</style>