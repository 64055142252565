<template>
	<span>{{ currency_display }}</span>
</template>

<script>
export default {
	props: [
		'price',
		'currency',
	],

	computed: {
		currency_display: function()
		{
			if (typeof this.price == 'undefined')
				return 'n/a'
			
			let value = this.price.toFixed(2).toString()
			if (value.slice(-3) == '.00')
				value = value.slice(0, -3)
			
			switch (this.currency.toUpperCase())
			{
				case 'USD':
				return '$' + value

				default:
				return value + ' ' + this.currency
			}
		},
	}
}
</script>