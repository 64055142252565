<template>
<span :class="[ 'header-link', { 'not-empty': saved.length } ]" @click="showSavedForLater">
	<font-awesome-icon :icon="['fas', 'heart']" class="d-none d-sm-inline-block icon" />
	<font-awesome-icon :icon="['far', 'heart']" class="d-sm-none icon" />
	<span class="d-none d-xl-inline">Saved for later</span>
	<span class="d-none d-sm-inline d-xl-none">Saved</span>
	<!-- <span class="count" v-if="saved.length">{{ saved.length }}</span> -->
</span>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	computed: {
		...mapState({
			saved: state => state.customer.lists[0] ? state.customer.lists[0].items : [],
		}),
	},

	methods: {
		...mapActions([
			'showSavedForLater'
		]),
	},
}
</script>
<style lang="scss" scoped>
.not-empty {
	color: #800 !important;

	.icon {
		color: #800;
	}
}

.count {
	position: relative;
	top: -1px;
	margin-left: 1px;
	padding: 1px 5px;
	color: #fff;
	background: #ea0000;
	border-radius: 20px;
	font-size: 11px;
	font-weight: bold;
}
</style>