<template>
<div>
	<div class="background" @click="close()"></div>
	<div class="all-categories-menu">
		<div class="close-modal" @click="close">&times;</div>
		
		<div :class="[ 'top-categories', { hidden: submenu } ]">
			<span @click="topCategoryClick(category.url)" v-for="category in categories" :key="category.url" :to="'/' + category.url + '/'" v-html="category.menu_title" :class="[ 'link', category.class, { active: root_category && category.url == root_category.url }, { hidden: category.show_in_menu === false } ]"></span>
			<nuxt-link to="/shipping-and-returns/" @click.native="close()" class="separate">Free shipping &amp;&nbsp;returns</nuxt-link>
		</div>
		
		<div v-for="category in categories" :key="category.url" :class="[ 'submenu', { hidden: submenu != category.url }, { show: submenu == category.url } ]">
			<span class="back" @click="back"><font-awesome-icon :icon="['far', 'chevron-left']" class="icon" />&nbsp;Back</span>
			<div v-if="category.subcategories && category.subcategories.length">
				<nuxt-link v-if="category.show_in_menu !== false" :to="'/' + category.url + '/'" @click.native="close()" class="subcategory header" v-html="category.menu_title"></nuxt-link>
				<div v-for="subcategory in category.subcategories" :key="subcategory.url" :class="{ 'has-subcategories': subcategory.subcategories }">
					<nuxt-link v-if="!subcategory.skip_to_subcategories && subcategory.show_in_menu !== false" :to="'/' + subcategory.url + '/'" @click.native="close()" class="subcategory level-2">
						<span>
							<span v-html="subcategory.menu_title"></span>
							<span class="count">{{ product_counts[subcategory.collection] ? product_counts[subcategory.collection] : '' }}</span>
						</span>
					</nuxt-link>
					<template v-for="subcategory in subcategory.subcategories">
						<nuxt-link v-if="product_counts[subcategory.collection] && subcategory.show_in_menu !== false" :to="'/' + subcategory.url + '/'" @click.native="close()" class="subcategory level-3" :key="subcategory.url">
							<span>
								<span v-html="subcategory.menu_title"></span>
								<span class="count">{{ product_counts[subcategory.collection] ? product_counts[subcategory.collection] : '' }}</span>
							</span>
						</nuxt-link>
					</template>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default
{
	data: function()
	{
		return {
			submenu: null,
		}
	},
	
	computed: {
		...mapState({
			categories: state => state.categories,
			categories_by_url: state => state.categories_by_url,
			category: state => state.current_category,
			root_category: state => state.root_category,
			product_counts: state => state.collections_product_counts,
			// visible: state => state.show_all_categories_menu,
		}),
	},
	
	methods: {
		...mapActions([
			'hideAllCategoriesMenu',
			'setCollectionsProductCounts',
		]),
		
		close()
		{
			this.hideAllCategoriesMenu()
		},
		
		topCategoryClick(url)
		{
			if (this.categories_by_url[url].subcategories && this.categories_by_url[url].subcategories.length)
			{
				this.submenu = url
				return
			}
			
			this.$router.push('/' + url + '/')
			this.close()
		},
		
		back()
		{
			this.submenu = null
		},
		
		keyboardEvent: function(event)
		{
			if (!event)
				return

			switch (event.keyCode)
			{
				case 27:
					this.close()
				return
			}
		},
	},
	
	mounted()
	{
		window.addEventListener('keydown', this.keyboardEvent)
		document.body.style.overflow = 'hidden'
		
		if (!Object.keys(this.product_counts).length)
		{
			this.$api.countCollectionsProducts().then((result) =>
			{
				if (result && result.status == 'ok')
					this.setCollectionsProductCounts(result.counts)
			})
		}
		
		this.$track.event({
			gtm: {
				category: 'Navigation',
				action: 'All categories menu opened',
			},
			fb: {
				event: 'AllCategoriesMenuOpened',
			}
		})
	},

	destroyed()
	{
		window.removeEventListener('keydown', this.keyboardEvent)
		if (!this.modals_count)
			document.body.style.overflow = 'auto'
		
		this.$track.event({
			gtm: {
				category: 'Navigation',
				action: 'All categories menu closed',
			},
			fb: {
				event: 'AllCategoriesMenuClosed',
			}
		})
	},
}
</script>

<style lang="scss" scoped>
@keyframes slideFromLeft {
	from { transform: translate(-200px); opacity: 0; }
	to { transform: translate(0); opacity: 1; }
}

@keyframes slideFromRight {
	from { transform: translate(200px); opacity: 0; }
	to { transform: translate(0); opacity: 1; }
}

@keyframes slideOutToLeft {
	0% { transform: translate(0); opacity: 1; left: 0; }
	90% { transform: translate(-300px); opacity: 1; left: 0; }
	100% { transform: translate(-10000px); opacity: 0; left: -10000px; }
}

.background {
	z-index: 11000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.54);
	cursor: pointer;
}

.all-categories-menu {
	z-index: 11000;
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0;
	left: 0;
	bottom: 0;
	width: 270px;
	padding: 10px 25px 25px 25px;
	background: #fff;
	box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.5);
	animation: slideFromLeft 0.15s;
	
	.close-modal {
		position: absolute;
		top: 10px;
		right: 7px;
	}
	
	.top-categories {
		font-size: 18px;
		line-height: 2.5;
		
		&.hidden {
			position: absolute;
			left: -10000px;
			animation: slideOutToLeft 0.15s linear;
			opacity: 0;
		}
		
		a,
		.link {
			display: block;
			color: #000;
			cursor: pointer;
			text-decoration: none;
			
			&.separate {
				margin-top: 3rem;
			}
			
			&:hover {
				color: $hover-color;
			}
			
			&.highlight {
				color: #ea0000;
			
				&:hover {
					color: #f66;
				}
			}
			
			&.hidden {
				display: none;
			}
		}
	}
	
	.submenu {
		
		&.show {
			animation: slideFromRight 0.15s;
		}
		
		&.hidden {
			position: absolute;
			left: -10000px;	
			opacity: 0;
		}
		
		.back {
			cursor: pointer;
			display: block;
			font-size: 13px;
			font-weight: 300;
			margin-left: -11px;
			margin-bottom: 1rem;

			@include black_link;
			
			.icon {
				font-size: 10px;
				margin-right: 3px;
			}
		}
	}
	
	.subcategory {
		display: block;
		color: #000;
		padding: 2px 0;
		text-decoration: none;
		white-space: nowrap;

		&:hover {
			color: $hover-color;
		}
		
		&.header {
			font-weight: bold;
		}

		& > span {
			padding: 5px 10px;
			margin-left: -10px;

			.count {
				font-size: 12px;
				font-style: italic;
				font-weight: 200;
			}
		}

		&.nuxt-link-exact-active,
		&.nuxt-link-active {

			&:hover {
				color: #000;
				text-decoration: none;
			}

			& > span {
				background: #ececec;
				border-radius: 13px;
			}
		}
	}
	
	.has-subcategories {
		margin-bottom: 20px;

		.level-2 {
			font-weight: bold;
			cursor: default;

			&:hover {
				color: #000;
				text-decoration: none;
			}
		}
	}
}
</style>